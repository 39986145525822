import React, { useEffect } from 'react';
import {FormattedMessage} from "react-intl";
import messages from 'intl/messages.properties';
import moment from "moment";
import { reload } from 'use-axios';
import { useHistory } from "react-router-dom";

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination
} from '@material-ui/core';
import listStyle from '../../../../styles/ListStyle';
import EnhancedTableHead from '../../../../shared/EnhancedTableHead';
import reservation from "../../../Reservations/Reservation/path";

// body of the table
function ReminderBody ({ shownReminders, remNo, productData, order, orderBy, rowsPerPage, isSelected, history }) {

  let productName = null;
  let reminderArray = [];

  // creates the values for the table, so they can be sorted more easily
  for (let i = 0; i < shownReminders.length; i++) {
    if (shownReminders[i].reservationGroups[0]?.reservationGroupProgramItems.length > 0) {
      const productIdNumber = shownReminders[i].reservationGroups[0]?.reservationGroupProgramItems[0].productId;

      for (let j = 0; j < productData.length; j++) {
        if (productIdNumber === productData[j].id) {
          productName = productData[j].name;
          break;
        }
      }
    }

    reminderArray[i] = {
      id: shownReminders[i].id,
      groupName: shownReminders[i].reservationName,
      productName: productName,
      date: moment(shownReminders[i].reactionToReservation).format("YYYYMMDD")
    };
  }

  return (
    <TableBody>
      {stableSort(reminderArray, getSorting(order, orderBy))
        .slice(remNo, remNo + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.id);
          return (
            <TableRow
              hover
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={index}
              selected={isItemSelected}
              onClick={() => history.push(reservation + `/`+ row.id)}
              className="pointer"
            >
              <TableCell>
                {row.groupName}
              </TableCell>
              <TableCell>
                {moment(row.date).format("DD.MM.YYYY")}
              </TableCell>
            </TableRow>
          )
        })
      }
    </TableBody>
  )
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const useStyles = makeStyles(listStyle);

const headRows = [
  { id: 'groupName', numeric: false, label: 'Ryhmä' },
  { id: 'date', numeric: false, label: 'Päivämäärä' },
];


export default function ReminderHead ({ productData, shownReminders }) {
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const isSelected = name => selected.indexOf(name) !== -1;

  useEffect(() => {
    const interval = setInterval(() => reload(shownReminders), 30000);
    return () => clearInterval(interval);
  }, [shownReminders]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  return (
    <div
      // className={classes.root}  ADDS SPACE BETWEEN TABLE AND TOP BAR
      style={{ border: "solid 0.5px #f2f2f2" }}
    >
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            headRows={headRows}
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={shownReminders.length}
          />
          <ReminderBody
            productData={productData}
            remNo={page * rowsPerPage}
            shownReminders={shownReminders}
            order={order}
            orderBy={orderBy}
            rowsPerPage={rowsPerPage}
            isSelected={isSelected}
            history={history}
          />
        </Table>
      </div>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[3, 5, 10]}
        component="div"
        count={shownReminders.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={<FormattedMessage id={messages.rowsPerPage}/>}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
