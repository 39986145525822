import { useEffect } from 'react';
import { useKeycloak } from 'react-keycloak';
import axios from 'axios';

export default () => {
  const {
    keycloak: { token }
  } = useKeycloak();
  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [token]);
  return null;
};
