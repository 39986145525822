import {FormattedMessage} from "react-intl";
import messages from 'intl/messages.properties';

import {
  makeStyles,
  Paper,
  Box
} from "@material-ui/core";

import Reminder from './Reminders';
import Newsletters from './Newsletters';
import TodaysProgram from './TodaysProgram';
import useAxios from "use-axios";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: "20px",
    margin: "20px 0"
  }
}));

export default function Private() {
  const classes = useStyles();
  const {data: reservationData} = useAxios(`/api/reservation/active`);
  const {data: productData} = useAxios(`/api/product`);
  const {data: resourceData} = useAxios(`/api/resource`);
  const {data: newsletterData} = useAxios(`/api/newsletter`);
  const {data: noteData} = useAxios(`/api/note`);


  return <>
    <Paper className={classes.paper} style={{ marginTop: 0 }} >
      <Box displayPrint="block">
        <h2>
          <FormattedMessage id={messages.todayProgramTitle} />
        </h2>
        <TodaysProgram reservationData={reservationData}
                       productData={productData}
                       resourceData={resourceData}
                       noteData={noteData}
        />
      </Box>
    </Paper>
    <Paper className={classes.paper}>
      <Box displayPrint="none">
        <h2>
          <FormattedMessage id={messages.reminderTitle} />
        </h2>
        <Reminder reservationData={reservationData}
                  productData={productData}
                  resourceData={resourceData}
        />
      </Box>
    </Paper>
    <Paper className={classes.paper}>
      <Box displayPrint="none">
        <h2>
          <FormattedMessage id={messages.newsletterTitle} />
        </h2>
        <Newsletters newsletterData={newsletterData}/>
      </Box>
    </Paper>
  </>;
}
