import {makeStyles} from "@material-ui/core/styles/index";


const useStyles = makeStyles(theme => ({

  footer: {
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    alignItems: "center",
    width: "auto",
    height: "60px",
    background: "#f2f2f2",
  },
  footerText: {
    marginRight: 20,
    marginLeft: 200,
    fontFamily: "Raleway, sans-serif"
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <span className={classes.footerText}>
        Kauppaneuvos 2.0.0
      </span>
    </div>
  )

}
