import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';

import {
  FormControl,
  TextField
} from '@material-ui/core';
import {useFormContext} from "react-hook-form";
import useAuthorize from "@postinumero/authorization/useAuthorize";


export default function Report(props) {
  const { classes } = props;

  const { register } = useFormContext();

  return (<>
      <fieldset disabled={!useAuthorize({ allow: "Asiakaspalvelu" })}>
      <FormControl className={classes.formControl}>
        <label className={classes.label}>
          <FormattedMessage id={messages.actualisedGroupSize}/>
        </label>
        <TextField id="actualisedGroupSize"
                   name="actualisedGroupSize"
                   inputRef={register}
                   type="number"
                   variant="filled"
                   className={classes.numberField}/>
      </FormControl>

      <FormControl className={classes.formControl}>
        <label className={classes.label}>
          <FormattedMessage id={messages.payingVisitors}/>
        </label>
        <TextField id="payingVisitors"
                   name="payingVisitors"
                   inputRef={register}
                   type="number"
                   variant="filled"
                   className={classes.numberField}/>
      </FormControl>

      <FormControl className={classes.formControl}>
        <label className={classes.label}>
          <FormattedMessage id={messages.freeTickets}/>
        </label>
        <TextField id="freeTickets"
                   name="freeTickets"
                   inputRef={register}
                   type="number"
                   variant="filled"
                   className={classes.numberField}/>
      </FormControl>

      <FormControl className={classes.formControl}>
        <label className={classes.label}>
          <FormattedMessage id={messages.actualisedPrice}/>
        </label>
        <TextField id="actualisedPrice"
                   name="actualisedPrice"
                   inputRef={register}
                   type="text"
                   InputProps={{inputProps: { pattern: "[0-9]+([,.][0-9]{1,2})?", step: 0.01}}}
                   variant="filled"
                   className={classes.numberField}/>
      </FormControl>

      <FormControl className={classes.formControlMultiline}>
        <label className={classes.label}>
          <FormattedMessage id={messages.reportFromVisitation}/>
        </label>
        <TextField id="reportFromVisitation"
                   name="reportFromVisitation"
                   inputRef={register}
                   variant="filled"
                   multiline
                   className={classes.multilineTextField}/>
      </FormControl>
      </fieldset>
    </>
  )
}

