import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';
import * as PropTypes from "prop-types/index";
import BackButton from '../Pages/shared/BackButton';

import DeleteDialog from "DeleteDialog";

import {
  Button,
  FormControl,
  Box
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave,
  faTrashAlt,
  faCopy,
  faPrint
} from "@fortawesome/free-solid-svg-icons/index";
import Authorize from "@postinumero/authorization/Authorize";

export default function FormActionButtons(props) {
  const { values, classes, remove, copy, allow, disabled, setCopySuccess, reset } = props;
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  return (
    <Box display="block" displayPrint="none">
      <FormControl className={classes.formControlButtons}>
        <div>
          <BackButton class={classes.formActionButton}
                      style={{ float: "left" }}/>
        </div>
        <div>
          { props.remove && <>
            <Authorize allow={allow}>
              <Button variant="contained"
                      disabled={disabled}
                      className={classes.redButton}
                      onClick={() => setDeleteDialogOpen(true)}>
                <FontAwesomeIcon icon={faTrashAlt}
                                 style={{marginRight: 10}} />
                <FormattedMessage id={messages.remove} />
              </Button>
            </Authorize>
            <DeleteDialog open={deleteDialogOpen}
                          setOpen={setDeleteDialogOpen}
                          data={values}
                          remove={remove}
                          classes={classes}/>

            <Button variant="contained"
                    disabled={disabled}
                    className={classes.formActionButton}
                    onClick={() => window.print()}>
              <FontAwesomeIcon icon={faPrint}
                               style={{marginRight: 10}}/>
              <FormattedMessage id={messages.print}/>
            </Button>
            {copy && <Authorize allow={allow}>
              <Button variant="contained"
                      disabled={disabled}
                      className={classes.formActionButton}
                      onClick={async () => {
                        const resp = await copy(values);
                        reset(resp);
                        setCopySuccess(true);
                      }}>
                <FontAwesomeIcon icon={faCopy}
                                 style={{marginRight: 10}}/>
                <FormattedMessage id={messages.copy} />
              </Button>
            </Authorize>}
          </>
          }
          <Authorize allow={allow}>
            <Button variant="contained"
                    disabled={disabled}
                    type="submit"
                    className={classes.greenButton}>
              <FontAwesomeIcon icon={faSave}
                               style={{marginRight: 10}} />
              <FormattedMessage id={messages.save} />
            </Button>
          </Authorize>
        </div>

      </FormControl>
    </Box>
  )
}


FormActionButtons.propTypes = {
  values: PropTypes.object,
  classes: PropTypes.object,
  remove: PropTypes.func,
  copy: PropTypes.func
};
