import ErrorBoundary from 'react-error-boundary';
import {FormattedMessage} from "react-intl";
import messages from 'intl/messages.properties';
import {Alert} from "react-bootstrap";
import BackButton from "../Pages/shared/BackButton";

function MyFallbackComponent({error, componentStack}) {
  return (
    <>
      {error.response?.status === 404 ?
        <Alert variant={"danger"}>
          <h2><strong><FormattedMessage id={messages.notfound}/></strong></h2>
          <p>
            <FormattedMessage id={messages.notFoundBody}/>
          </p>
        </Alert> :

        <Alert variant={"danger"}>
        <h2><strong><FormattedMessage id={messages.otherError}/></strong></h2>
        </Alert>
      }
      <BackButton/>
    </>
  );
}

export default function RequestErrorBoundary({children}) {
  return (
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      {children}
    </ErrorBoundary>
  );
}


// TODO: Joku fiksumpi tänne
