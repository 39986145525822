import Page from 'Page';
import RequestErrorBoundary from "../../../shared/RequestErrorBoundary";
import { matchProduct, newProduct } from "./path";
import products from "../../Products/path";
import NewProduct from "./NewProduct";
import EditProduct from "./EditProduct";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

function Product() {
  return (
      <Page>
        <RequestErrorBoundary>
          <Switch>
            <Route path={newProduct} component={NewProduct} />
            <Route path={matchProduct} exact component={EditProduct} />
            <Redirect to={products} />
          </Switch>
        </RequestErrorBoundary>
      </Page>
  );
}

export default Product;
