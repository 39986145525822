import React, {useEffect} from 'react';
import reservation from "../Reservation/path";
import Icons from '../Icons';

import listStyle from '../../../styles/ListStyle';
import EnhancedTableHead from '../../../shared/EnhancedTableHead';
import Search from '../../../shared/Search';

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination
} from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';
import moment from "moment";
import {useHistory} from "react-router-dom";
import useAxios, {reload} from "use-axios";
import IndexedSearch from "../IndexedSearch";

const useStyles = makeStyles(listStyle);
const DATE = "DD.MM.YYYY";
const FIRST_DATE = "DD.MM.";
const headRows = [
  { id: 'reservationName', numeric: false, label: 'Ryhmä' },
  { id: 'reserver', numeric: false, label: 'Varaaja' },
  { id: 'reservationSize', numeric: false, label: 'Ryhmän koko' },
  { id: 'productIcon', numeric: false, label: 'Ohjelma', sortable: false },
  { id: 'visitingDate', numeric: false, label: 'Vierailuajankohta'}
];

function asc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'asc' ? (a, b) => asc(a, b, orderBy) : (a, b) => -asc(a, b, orderBy);
}

export default function EnhancedTable({ productData }) {
  const {data: pastReservations} = useAxios(`/api/reservation/past`);

  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('visitingDate');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState('');
  const [pastReservationsList, setPastReservationsList] = React.useState(pastReservations);
  const [pastListWithDates, setPastListWithDates] = React.useState(pastReservationsList);
  const isSelected = name => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, pastReservations.length - page * rowsPerPage);

  useEffect(() => {
    const reservationInterval = setInterval(() => reload(`/api/reservation/past`), 30000);
    return () => {
      clearInterval(reservationInterval);
    };
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  useEffect(() => {
    const newPastReservations = [...pastReservationsList];
    newPastReservations.forEach((reservation) => {
      const visitAmount = reservation.reservationVisitingTimes.length;
      if (visitAmount < 1) {
        return;
      }
      const firstDate = reservation.reservationVisitingTimes[0]?.visitingDate;
      const lastDate = moment(reservation.reservationVisitingTimes[visitAmount - 1]?.visitingDate).format(DATE);
      reservation.reserver = reservation.reservationContacts[0]?.organization;
      reservation.pipedrive = reservation.lastModifiedBy === "Kauppaneuvos";
      reservation.visitingDate = moment(firstDate);
      if (visitAmount > 1) {
        reservation.visitingDateLabel = moment(firstDate).format(FIRST_DATE) + "-" + lastDate + " (" + visitAmount + ")";
      } else {
        reservation.visitingDateLabel = lastDate;
      }
    });
    setPastListWithDates(newPastReservations);
    setPage(0);
  }, [pastReservationsList])

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'asc';
    setOrder(isDesc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <div style={{ border: "solid 0.5px #f2f2f2" }}>

      <IndexedSearch past={true} setReservations={setPastReservationsList} classes={classes}/>
      <div className={classes.searchContainer} >
        <Search searchValue={searchValue}
                setSearchValue={setSearchValue} />
      </div>

      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            headRows={headRows}
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={pastListWithDates.length}
          />
          <TableBody>
            {stableSort(pastListWithDates, getSorting(order, orderBy))
              .filter( reservation => reservation.reservationName.toUpperCase().includes( searchValue.toUpperCase() ) || reservation.reserver?.toUpperCase().includes( searchValue.toUpperCase() ) )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    onClick={() => history.push(reservation + `/`+ row.id)}
                    className="pointer"
                  >
                    <TableCell>
                      {row.reservationName}
                    </TableCell>
                    <TableCell>
                      {row.reserver}
                    </TableCell>
                    <TableCell>
                      {row.reservationSize}
                    </TableCell>
                    <TableCell>
                      <Icons productData={productData} reservation={row} />
                    </TableCell>
                    <TableCell>
                      {row.visitingDateLabel}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={pastListWithDates.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={<FormattedMessage id={messages.rowsPerPage}/>}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
