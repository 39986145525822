import { FormattedMessage } from 'react-intl';
import { Suspense } from 'react';
import Spinner from 'Spinner';
import messages from 'intl/messages.properties';
import OfferBase from './OfferBase';

export default function EditOfferBase(props) {
  const { offerValue, handleSave, setShowEditor} = props;

  return (
    <>
      <h2>
        <FormattedMessage id={messages.editOffer}/>
      </h2>
      <Suspense fallback={<Spinner/>}>
        <OfferBase offerValue={offerValue}
                   setShowEditor={setShowEditor}
                   handleSave={handleSave}
        />
      </Suspense>
    </>
  );
}
