const invitesStyle = theme => ({
  containerFluid: {
    margin: "auto !important"
  },
  paper: {
    padding: "20px 10px",
  },
  pageContainer: {
    marginLeft: "0px !important"
  }
})

export default invitesStyle;
