import React, {useEffect} from 'react';
import {
  Button,
  DialogTitle, FormControl, TextField,
} from "@material-ui/core";
import messages from 'intl/messages.properties';
import {FormattedMessage} from "react-intl";
import tableIcons from "../../../../shared/definitions/tableIcons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import MaterialTable from "material-table";
import {isEmpty, camelCase} from 'lodash';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import MomentUtils from "@date-io/moment";
import Moment from "moment";
import {extendMoment} from "moment-range";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Alert} from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import reservedResourceStatus from "../../../../shared/definitions/reservedResourceStatus"
import useAuthorize from "@postinumero/authorization/useAuthorize";
import productTypes from "../../../../shared/definitions/productTypes";
import TablePagination from "@material-ui/core/TablePagination";

const moment = extendMoment(Moment);

// TODO: Kielet?

export default function ProgramItemEditor(props) {
  const {onClose, productData, saveProgramItem, removeProgramItem, checkProductLimitation, classes, reservationGroups, programItem, resources, selectedDate} = props;

  const initialShowResources = programItem?.resourcesRequired === true
    ? programItem.resourcesRequired
    : false;

  const [selectedProduct, setSelectedProduct] = React.useState();
  const [errors, setErrors] = React.useState({});
  const [, updateState] = React.useState();
  const [newItem, setNewItem] = React.useState(false);
  const [id, setId] = React.useState();
  const [tempId, setTempId] = React.useState();
  const [productName, setProductName] = React.useState();
  const [showProduct, setShowProduct] = React.useState(true);
  const [productDescription, setProductDescription] = React.useState();
  const [length, setLength] = React.useState();
  const [productPrice, setProductPrice] = React.useState();
  const [productPricingInformation, setProductPricingInformation] = React.useState();
  const [startingTime, setStartingTime] = React.useState();
  const [endingTime, setEndingTime] = React.useState();
  const [group, setGroup] = React.useState();
  const [reservedResources, setReservedResources] = React.useState([]);
  const [showResources, setShowResources] = React.useState(initialShowResources);
  const [resourceList, setResourceList] = React.useState(resources);
  const [productList, setProductList] = React.useState(productData.sort((p1, p2) => p1.name > p2.name));
  const [productType, setProductType] = React.useState("All");
  const [productPageNr, setProductPageNr] = React.useState(0);
  const [shownProducts, setShownProducts] = React.useState([]);
  const [resourcePageNr, setResourcePageNr] = React.useState(0);
  const [shownResources, setShownResources] = React.useState([]);


  const productTableRef = React.createRef();
  const resourceTableRef = React.createRef();

  useEffect(() => {
    setShownProducts(productList.slice(productPageNr * 10, productPageNr * 10 + 10));
  }, [productList, productPageNr]);

  useEffect(() => {
    setShownResources(resourceList.slice(resourcePageNr * 10, resourcePageNr * 10 + 10));
  }, [resourceList, resourcePageNr]);

  useEffect(() => {
    if (productType === "All") {
      setProductList(productData.sort((p1, p2) => p1.name > p2.name));
    } else {
      setProductList(productData.filter(p => p.productType === productType).sort((p1, p2) => p1.name > p2.name));
    }
  }, [productData, productType]);

  // Set values from product to be edited
  useEffect(() => {
    if (!programItem || (!programItem.id && !programItem.tempId)) {
      clearForm();
      return;
    }

    const initialShowResources = programItem?.resourcesRequired === true
      ? programItem.resourcesRequired
      : false;

    setId(programItem.id || undefined);
    setTempId(programItem.tempId || undefined);
    setProductName(programItem.productName);
    setProductDescription(programItem.productDescription);
    setShowProduct(programItem.showProduct);
    setLength(programItem.length);
    setProductPrice(programItem.productPrice);
    setProductPricingInformation(programItem.productPricingInformation);
    setStartingTime(programItem.startingTime);
    setEndingTime(programItem.endingTime);
    setShowResources(initialShowResources);

    for (let i = 0; i < reservationGroups.length; i++) {
      if (reservationGroups[i].id === programItem.group || reservationGroups[i].tempId === programItem.group) {
        setGroup(i)
      }
    }
    setReservedResources([...programItem.reservedResources]);
    setNewItem(false);
    setSelectedProduct(programItem.productId);
  }, [programItem, reservationGroups]);

  const selectProduct = (data) => {
    if (!data) {
      return;
    }
    setProductName(data.name);
    setProductDescription(data.description);
    setShowProduct(true);
    setLength(data.length);
    setProductPricingInformation(data.pricingInformation);
    setProductPrice("");
    if (!startingTime) {
      setStartingTime(moment("08:00", "HH:mm").toDate());
      setEndingTime(moment("08:00", "HH:mm").add(data.length, "m").toDate());
    } else {
      setEndingTime(moment(startingTime).add(data.length, "m").toDate())
    }

    setReservedResources([]);
    setSelectedProduct(data.id);
    const newErrors = errors;
    delete newErrors.product;
    delete newErrors.group;
    setErrors(newErrors);
  };

  const handleTimeChange = (date) => {
    setStartingTime(date);
    setEndingTime(moment(date).add(length, "m").toDate())
  };

  const handleLengthChange = (event) => {
    setLength(event.target.value);
    setEndingTime(moment(startingTime).add(event.target.value, "m").toDate())
  };

  const handleShowProductCheckChange = event => {
    let showProduct;
    showProduct = !!event.target.checked;
    setShowProduct(showProduct);
  }

  const handleResourceCheckChange = (event) => {
    let showResourcesOpen;
    showResourcesOpen = !!event.target.checked;
    setShowResources(showResourcesOpen);
  };

  const handleChosenCheckChange = (event, rowData) => {
    let newReservedResources;
    if (event.target.checked) {
      newReservedResources = [...reservedResources, {
        chosen: true,
        accepted: rowData.accepted,
        resource: rowData.id
      }]
    } else {
      newReservedResources = reservedResources.filter((resource) => resource.resource !== rowData.id);
    }
    setReservedResources([...newReservedResources]);
  };

  const handleAcceptedCheckChange = (event, rowData) => {
    const resourceIndex = reservedResources.findIndex(resource => resource.resource === rowData.id);
    if (resourceIndex < 0) {
      return;
    }
    const newReservedResources = Object.assign([], reservedResources, {[resourceIndex]: {
        ...reservedResources[resourceIndex],
        accepted: event.target.checked
      }});
    setReservedResources(newReservedResources);
  };

  const handleCloseDialog = () => {
    clearForm();
    onClose();
  };

  const onSubmit = () => {
    if (newItem) {
      if (!selectedProduct) {
        const newErrors = errors || {};
        newErrors.product = {message: messages.productRequired};
        setErrors(newErrors);
        updateState({});
        return;
      }
      if (group === undefined) {
        const newErrors = errors || {};
        newErrors.group = {message: messages.groupRequired};
        setErrors(newErrors);
        updateState({});
        return;
      }
    }

    let newErrors = {};
    if (!checkProductLimitation(selectedProduct, id || tempId,
                                selectedDate,
                                moment(startingTime).format("HH:mm"),
                                moment(endingTime).format("HH:mm"))) {
      newErrors = errors || {};
      newErrors.product = {message: messages.productLimited};
      updateState({});
    }

    const product = productData.find(p => p.id === selectedProduct);
    if (product.limited && product.maxGroupSize && reservationGroups[group].groupSize > product.maxGroupSize) {
      newErrors = errors || {};
      newErrors.group = {message: messages.groupTooLarge};
      updateState({});
    }

    if (Object.entries(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    saveProgramItem(
      id,
      tempId,
      selectedProduct,
      productDescription,
      showProduct,
      moment(startingTime).format("YYYY-MM-DD"),
      length,
      group,
      productPrice,
      productPricingInformation,
      moment(startingTime).format("HH:mm"),
      reservedResources,
      showResources
    );
    handleCloseDialog();

  };

  const clearForm = () => {
    setId(undefined);
    setTempId(undefined);
    setProductName("");
    setProductDescription("");
    setShowProduct(true);
    setLength("");
    setProductPricingInformation("");
    setProductPrice("");
    setStartingTime(undefined);
    setEndingTime(undefined);
    setShowResources(false);
    setGroup(0);
    setReservedResources([]);
    setErrors({});
    setSelectedProduct(undefined);
    setNewItem(true);
  };

  const isAvailable = (resource, startTime, length) => {
    if (resource.reserved === undefined) {
      return true;
    }
    const endTime = moment(startTime);
    endTime.add(length, "minutes");
    const productRange = moment.range(startTime, endTime);
    for (let i = 0; i < resource.reserved.length; i++) {
      if (resource.reserved[i].overlaps(productRange)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (!selectedProduct) {
      return;
    }

    // Set resource statuses
    let newResourceList = [];
    reservedResources.forEach((reservedResource) => {
      const resource = resources.find((resource) => resource.id === reservedResource.resource);
      if (!resource) {
        return;
      }
      newResourceList.push({
        ...resource,
        chosen: reservedResource.chosen,
        accepted: reservedResource.accepted,
        status: reservedResourceStatus[reservedResource.status],
      });
    });

    // Filter resources without correct language skills
    // Set resource reserved times (for coloring red)
    const selectedGroup = reservationGroups[group];
    newResourceList = newResourceList.concat([...resources].filter((resource) => {
      return newResourceList.findIndex(r => r.id === resource.id) < 0 && selectedProduct && resource.productQualifications.findIndex((qualification) => {
        return qualification.productId === selectedProduct && selectedGroup.languages.every((lang) => qualification.languages.includes(lang));
      }) >= 0
    })).map((resource) => {
      const reservedTimes = [];
      resource.programItems.forEach((programItem) => {
        const startTime = moment(programItem.itemDate + " " + programItem.startingTime);
        const endTime = moment(startTime);
        endTime.add(programItem.length, "minutes");
        reservedTimes.push(moment.range(startTime, endTime));
      });
      return {
        ...resource,
        reserved: reservedTimes
      }
    });

    setResourceList(newResourceList.sort((rr1, rr2) => {
      if (rr1.chosen === rr2.chosen) {
        return rr1.fullName > rr2.fullName;
      } else {
        return rr1.chosen > rr2.chosen;
      }
    }));
  }, [reservedResources, resources, selectedProduct, group, reservationGroups]);

  // Update resource table when resource list updates
  useEffect(() => {
    productTableRef.current && productTableRef.current.onQueryChange();
  }, [productList, shownProducts, productTableRef])

  useEffect(() => {
    resourceTableRef.current && resourceTableRef.current.onQueryChange();
  }, [resourceList, shownResources, resourceTableRef]);

  return (
    <>
      <DialogTitle id="simple-dialog-title">
        <FormattedMessage id={newItem ? messages.addProgramItemTitle : messages.editProgramItemTitle}/>
      </DialogTitle>
      <div className={classes.dialogContent}>

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <fieldset disabled={!useAuthorize({allow: "Asiakaspalvelu"})} style={{width: "100%"}}>

            {newItem &&

            <>
            <Select id="productType"
                    name="productType"
                    value={productType}
                    onChange={(event) => setProductType(event.target.value)}
                    className={classes.select}
                    variant="filled"
                    style={{margin: "10px"}}>
              <MenuItem value={"All"}>
                <FormattedMessage id={messages.all}/>
              </MenuItem>
              {productTypes.map( (type, index) =>
                <MenuItem key={index}
                          value={type}>
                  <FormattedMessage id={messages[camelCase(type)]} />
                </MenuItem>
              )}
            </Select>
            <MaterialTable
              title={<FormattedMessage id={messages.products}/>}
              tableRef={productTableRef}
              columns={[
                {title: <FormattedMessage id={messages.product}/>, field: "name"},
                {title: <FormattedMessage id={messages.productType}/>, field: "productType", render: (rowData) => <FormattedMessage id={messages[camelCase(rowData.productType)]} />}]}
              data={() =>
                new Promise((resolve, reject) => {
                  resolve({
                    data: shownProducts
                  })
                })
              }
              icons={tableIcons}
              style={{marginBottom: "5px"}}
              onRowClick={(event, data) => {
                selectProduct(data)
              }}
              localization={{
                header: {
                  actions: <FormattedMessage id={messages.actions}/>
                },
                body: {
                  emptyDataSourceMessage: <FormattedMessage id={messages.emptyDataSourceMessage}/>
                },
                toolbar: {
                  searchTooltip: <FormattedMessage id={messages.search}/>,
                  searchPlaceholder: 'Haku'
                },
                pagination: {
                  labelDisplayedRows: ""
                }
              }}
              options={
                {
                  pageSize: 10,
                  search: false,
                  actionsColumnIndex: -1
                }
              }
              components={{
                Pagination: props => (
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    rowsPerPage={10}
                    count={productList.length}
                    page={
                        productPageNr
                    }
                    onChangePage={(e, page) =>
                      setProductPageNr(page)
                    }
                  />
                ),
              }}
            />
            </>
            }
            {selectedProduct && <Paper className={classes.paper}>
              <DialogTitle>
                <FormattedMessage id={messages.editProgramItemInfo}/>
              </DialogTitle>
              {!isEmpty(errors) && Object.values(errors).map((error, index) => {
                  return <Alert key={index} variant="danger">
                    <FormattedMessage id={error.message}/>
                  </Alert>
                }
              )}
              <FormControl className={classes.formControl}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.productName}/>
                </label>
                <TextField id="productName"
                           name="productName"
                           value={productName}
                           onChange={(event) => setProductName(event.target.value)}
                           disabled
                           className={classes.textField}
                           variant="filled"/>
              </FormControl>
              <FormControl className={classes.childFields}>
                <KeyboardTimePicker
                  ampm={false}
                  autoOk
                  inputVariant="filled"
                  variant="inline"
                  className={classes.timeField}
                  value={startingTime}
                  disabled={!newItem}
                  onChange={handleTimeChange}
                />
                <FontAwesomeIcon icon={faMinus}
                                 style={{width: "0.6em", margin: "0 8px"}}/>
                <KeyboardTimePicker
                  ampm={false}
                  autoOk
                  inputVariant="filled"
                  variant="inline"
                  disabled
                  className={classes.timeField}
                  value={endingTime}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.length}/>
                </label>
                <TextField id="length"
                           name="length"
                           type="number"
                           value={length}
                           disabled={!newItem}
                           onChange={handleLengthChange}
                           className={classes.textField}
                           variant="filled"/>
              </FormControl>
              <FormControl className={classes.formControlMultiline}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.description}/>
                </label>
                <TextField
                  id="productDescription"
                  name="productDescription"
                  value={productDescription}
                  onChange={(event) => setProductDescription(event.target.value)}
                  className={classes.textAreaField}
                  type="string"
                  multiline
                  rows="12"
                  variant="filled"
                  style={{minHeight: 100}}
                />
              </FormControl>
              <FormControl className={classes.formControlMultiline}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.pricingInformation}/>
                </label>
                <TextField
                  id="productPricingInformation"
                  name="productPricingInformation"
                  value={productPricingInformation}
                  onChange={(event) => setProductPricingInformation(event.target.value)}
                  className={classes.textAreaField}
                  type="string"
                  multiline
                  rows="4"
                  variant="filled"
                  style={{minHeight: 100}}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.price}/>
                </label>
                <TextField
                  id="productPrice"
                  name="productPrice"
                  type="text"
                  InputProps={{inputProps: { pattern: "[0-9]+([,.][0-9]{1,2})?", step: 0.01}}}
                  value={productPrice}
                  onChange={(event) => setProductPrice(event.target.value)}
                  className={classes.textField}
                  variant="filled"/>
              </FormControl>
              {newItem &&
              <FormControl className={classes.formControlMultiline} error={errors && errors.group !== undefined}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.group}/>
                </label>
                <Select
                  value={group}
                  className={classes.select}
                  onChange={(event) => {
                    setGroup(event.target.value);
                    const newErrors = errors;
                    delete newErrors.group;
                    setErrors(newErrors);
                  }}
                >
                  {reservationGroups.map((group, index) => (
                    <MenuItem key={index} value={index}>
                      {"Ryhmä " + (index + 1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>}
              <FormControl className={classes.formControl}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.showProduct}/>
                </label>
                <Checkbox
                  name={"chosen"}
                  color={"primary"}
                  checked={showProduct}
                  onChange={(event) => {handleShowProductCheckChange(event)}}/>
              </FormControl>
              <FormControl className={classes.formControl}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.resourceNeeded}/>
                </label>
                <Checkbox
                  name={"chosen"}
                  color={"primary"}
                  checked={showResources}
                  onChange={(event) => {handleResourceCheckChange(event)}}/>
              </FormControl>
            </Paper>}
            {showResources
              ?
              <MaterialTable
                tableRef={resourceTableRef}
                style={{marginTop: "10px", display: selectedProduct ? "inherit" : "none"}}
                title={<FormattedMessage id={messages.resources}/>}
                columns={[
                  {
                    title: <FormattedMessage id={messages.chosen}/>, field: "chosen", searchable: false,
                    render: rowData => <Checkbox checked={rowData.chosen ? "checked" : ""}
                                                 name={"chosen"}
                                                 color={"primary"}
                                                 onChange={(event) => {
                                                   handleChosenCheckChange(event, rowData)
                                                 }}/>
                  },
                  {title: <FormattedMessage id={messages.resource}/>, field: "fullName", searchable: true},
                  {
                    title: <FormattedMessage id={messages.accepted}/>, field: "accepted", searchable: false,
                    render: rowData => <Checkbox checked={rowData.accepted ? "checked" : ""}
                      //disabled={!rowData.chosen || rowData.status === reservedResourceStatus["ACCEPTED"]}
                                                 name={"accepted"}
                                                 color={"primary"}
                                                 onChange={(event) => {
                                                   handleAcceptedCheckChange(event, rowData)
                                                 }}/>

                  },
                  {
                    title: <FormattedMessage id={messages.status}/>, field: "status",
                    render: rowData =>
                      <span>{rowData.status || "--"}</span>
                  }
                ]}
                data={() =>
                  new Promise((resolve, reject) => {
                    resolve({
                      data: shownResources
                    })
                  })
                }
                icons={tableIcons}
                localization={{
                  header: {
                    actions: <FormattedMessage id={messages.actions}/>
                  },
                  body: {
                    emptyDataSourceMessage: <FormattedMessage id={messages.emptyDataSourceMessage}/>
                  },
                  pagination: {
                    labelDisplayedRows: ""
                  }
                }}
                options={{
                  pageSize: 10,
                  sorting: true,
                  search: false,
                  actionsColumnIndex: -1,
                  rowStyle: rowData => ({
                    backgroundColor: rowData.status !== reservedResourceStatus.ACCEPTED && rowData.status !== reservedResourceStatus.PENDING && (rowData.status === reservedResourceStatus.DENIED || !isAvailable(rowData, moment(startingTime), length)) ? "#FF9999" :
                      "#FFF"
                  })
                }}
                components={{
                  Pagination: props => (
                    <TablePagination
                      {...props}
                      rowsPerPageOptions={[10]}
                      rowsPerPage={10}
                      count={resourceList.length}
                      page={
                        resourcePageNr
                      }
                      onChangePage={(e, page) =>
                        setResourcePageNr(page)
                      }
                    />
                  ),
                }}
              />
              : ''}
              </fieldset>
        </MuiPickersUtilsProvider>
      </div>
      <FormControl className={classes.formControlButtons} display="block"
           style={{marginTop: "5px"}}>
        <div>
          <Button onClick={(event) => {
                  event.preventDefault();
                    handleCloseDialog()
                  }}
                  color="default"
                  variant="contained"
                  style={{float: "left"}}>
            <FormattedMessage id={messages.cancel}/>
          </Button>
        </div>
        <div>{useAuthorize({allow:"Myyntipalvelu"}) && !newItem && <Button variant="contained"
                             className={classes.redButton}
                             onClick={() => {
                               removeProgramItem(id, tempId);
                               handleCloseDialog()
                             }}>
          <FontAwesomeIcon icon={faTrashAlt}
                           style={{marginRight: 10}}/>
          <FormattedMessage id={messages.remove}/>
        </Button>}
        <Button className={classes.greenButton}
                style={{marginLeft: "5px"}}
                color="primary"
                variant="contained"
                onClick={onSubmit}
                >
          <FormattedMessage id={newItem ? messages.add : messages.ok}/>
        </Button>
        </div>
      </FormControl>
    </>
  )
}
