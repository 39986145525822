import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';
import { Suspense } from 'react';
import { Link } from 'react-router-dom';

import Spinner from 'Spinner';
import Page from 'Page';
import {resource} from "./Resource/path";
import List from './List';

import {
  makeStyles,
  Button,
  Box,
  Paper,
  Divider
} from "@material-ui/core";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus} from "@fortawesome/free-solid-svg-icons/index";
import Authorize from "@postinumero/authorization/Authorize";


const useStyles = makeStyles(theme => ({
  greenButton: {
    margin: "0 5px",
    float: "right",
    backgroundColor: "#068a3f",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#067a3d",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "0.9em"
    }
  },
  title: {
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 15px",
  }
}));


function Resources() {
  const classes = useStyles();

  return (
      <Page>

        <Suspense fallback={<Spinner />}>
          <Paper display="flex" style={{padding: "10px", margin: "10px 0"}}>

            <Box display="flex"
                 className={classes.title}>
              <Page.Title>
                <FormattedMessage id={messages.resource} />
              </Page.Title>
              <Authorize allow={"Myyntipalvelu"}>
                <Link to={resource+"/new"}>
                  <Button variant="contained"
                          className={classes.greenButton}>
                    <FontAwesomeIcon icon={faPlus}
                                     style={{marginRight: 10}}/>
                    <FormattedMessage id={messages.newResource} />
                  </Button>
                </Link>
              </Authorize>
            </Box>

            <Divider/>

            <List />
          </Paper>
        </Suspense>
      </Page>
  )
}
export default Resources;
