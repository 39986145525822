import { FormattedMessage } from 'react-intl';
import { Alert } from 'react-bootstrap';
import messages from 'intl/messages.properties';
import Page from 'Page';
import BackButton from 'BackButton';

export default () => (
  <Page>
    <Alert variant="danger">
      <FormattedMessage id={messages.forbidden} />
    </Alert>
    <BackButton />
  </Page>
);
