const EventColors = {
  GO_KOULULAIS : "#008000",     // green
  GO_TILAVARAUS: "#00FFFF",     // aqua
  GO_OPASTUKSET: "#bb86f2",     // purple
  GO_RAVINTOLA: "#8B4513",      // saddlebrown
  GU_KOULULAIS: "#FFA500",      // orange
  MUUT_KOHTEET: "#FFD700",      // gold
  GU_RAVINTOLA: "#4169E1",      // royalblue
  GU_OPASTUKSET: "#FFFFFF",     // white
  MANTTA_OPASTUKSET: "#ADFF2F", // greenyellow
  GU_TILAVARAUS: "#FF4500",     // orangered
  GO_TILAVUOKRAUS: "#FFFF00",   // yellow
  GU_MUUT: "#6B8E23",           // olivedrab
  HENKILOPALVELUT: "#008080",   // teal
  GU_TILAVUOKRAUS: "#C0C0C0",   // silver
  GO_MUUT: "#DEB887",           // burlywood
  ERIKOISOHJELMAT: "#EE82EE"    // violet
};

const StatusOK    = "#BBEEBB"; // Everyone has accepted
const StatusNotOK = "#FF9999"; // Everyone has not accepted

export default EventColors;

export { StatusOK, StatusNotOK };
