import React from "react";
import RichTextEditor from "react-rte";

// function that is called to use RichTextEditor in Form
function RTE({ value, setValue, classname }) {

  const [editorValue, setEditorValue] = React.useState(RichTextEditor.createValueFromString(value, 'markdown'));

  const handleChange = value => {
    setEditorValue(value);
    setValue(value.toString("markdown"));
  };

  // modifies the toolbar to add or delete RTE-buttons
  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'}
    ],
    LINK_BUTTONS: [
      {label: 'Link', style: 'unstyled', className: 'rte-button rte-button-link'},
      {label: 'Unlink', style: 'unstyled', className: 'rte-button rte-button-link-remove'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'},
      {label: 'blockquote', style: 'blockquote'}
    ]
  };

  return (
    <RichTextEditor
      value={editorValue}
      onChange={handleChange}
      required
      className={`rte-editor ${classname}`}
      name="bodyText"
      type="string"
      multiline
      variant="filled"
      toolbarConfig={toolbarConfig}
    />
  );
}

export default RTE;
