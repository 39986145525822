import { Suspense } from 'react';
import { Localize } from '@visma/react-intl-helpers';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Keycloak from 'Keycloak';
import { withAuthorization } from '@postinumero/authorization';
import { hot } from 'react-hot-loader';
import Pages from './Pages';
import pages from './Pages/path';
import Invites from './Invites';
import invites from './Invites/path';
import { customTheme } from "./styles/MuiTheme";
import {
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import Authenticated from "@postinumero/authorization/Authenticated";


const theme = createMuiTheme(customTheme);

function InvitesWrapper() {

  return (
    <Suspense fallback={null}>
      <MuiThemeProvider theme={ theme }>
        <Localize locale="fi-FI">
          <BrowserRouter>
            <Switch>
              <Route path={invites} exact component={Invites} />
              <Route path={pages} component={App} />
            </Switch>
          </BrowserRouter>
        </Localize>
      </MuiThemeProvider>
    </Suspense>
  );
}

function App() {
  return (
      <Keycloak>
        <Authenticated fallback={<></>}>
          <Pages />
        </Authenticated>
      </Keycloak>
  );
}

export default hot(module)(withAuthorization(InvitesWrapper));
