const reservationTypes = [
  "KOTIMAINEN_MYYJA",
  "AMMATTIMATKANJARJESTAJA",
  "KOULU",
  "YRITYS",
  "YKSITYISHENKILO",
  "MUUT",
  "SISAINEN",
];

export default reservationTypes;
