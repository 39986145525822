import React from 'react';
import {FormattedMessage} from "react-intl";
import messages from 'intl/messages.properties';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Divider,
  DialogActions,
  Button,
} from '@material-ui/core';
import formStyle from "../../../../styles/FormStyle";
import Authorize from "@postinumero/authorization/Authorize";

const useStyles = makeStyles(formStyle);

export default function Notes(props) {
  const { note, handleNoteSave, showNotes, setShowNotes } = props;
  const classes = useStyles();
  const [ noteValue, setNoteValue ] = React.useState(note.noteText);

  const handleClose = () => {
    setShowNotes(false)
  };

  const handleSave = () => {
    handleNoteSave({...note, noteText: noteValue});
    setShowNotes(false)
  };

  const handleChange = event => {
    setNoteValue( event.target.value );
  };


  return (
    <Dialog open={showNotes}
            onClose={handleClose}
            maxWidth = 'lg'
            fullWidth={true}>
      <DialogTitle>
        <FormattedMessage id={messages.notes}/>
      </DialogTitle>
      <DialogContent>
        <TextField type="string"
                   multiline
                   variant="filled"
                   value={noteValue}
                   onChange={handleChange}
                   className={classes.notesTextField}/>

      </DialogContent>
      <Divider/>

      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose}
                variant="contained"
                color="default"
                className={classes.button}>
          <FormattedMessage id={messages.cancel}/>
        </Button>
        <Authorize allow={"Asiakaspalvelu"}>
          <Button color="primary"
                  variant="contained"
                  className={classes.greenButton}
                  onClick={() => handleSave()}>
            <FormattedMessage id={messages.save}/>
          </Button>
        </Authorize>
      </DialogActions>
    </Dialog>
  )
}
