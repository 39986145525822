import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';
import Page from 'Page';
import BackButton from 'BackButton';

export default () => (
  <Page>
    <Page.Title>
      <FormattedMessage id={messages.noMatch} />
    </Page.Title>
    <BackButton />
  </Page>
);
