import React, {useEffect} from 'react';
import reservation from "../Reservation/path";
import Icons from '../Icons';

import listStyle from '../../../styles/ListStyle';
import EnhancedTableHead from '../../../shared/EnhancedTableHead';
import Search from '../../../shared/Search';

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination
} from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import useAxios, {reload} from "use-axios";
import IndexedSearch from "../IndexedSearch";

const useStyles = makeStyles(listStyle);
const DATE = "DD.MM.YYYY";
const FIRST_DATE = "DD.MM.";
const headRows = [
  { id: 'reservationName', numeric: false, label: 'Ryhmä'},
  { id: 'reserver', numeric: false, label: 'Varaaja' },
  { id: 'reservationSize', numeric: false, label: 'Ryhmän koko'},
  { id: 'productIcons', numeric: false, label: 'Ohjelma'},
  { id: 'visitingDate', numeric: false, label: 'Vierailuajankohta'},
  { id: 'pipedrive', numeric: false, label: 'Käsittelemättä'}
];

function asc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'asc' ? (a, b) => asc(a, b, orderBy) : (a, b) => -asc(a, b, orderBy);
}

export default function EnhancedTable({ productData }) {
  const {data: comingReservations} = useAxios(`/api/reservation/active`);

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('visitingDate');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState('');
  const [emptyRows, setEmptyRows] = React.useState(0);
  const [comingReservationsList, setComingReservationsList] = React.useState(comingReservations);
  const [comingListWithDates, setComingListWithDates] = React.useState(comingReservationsList);
  const [filteredList, setFilteredList] = React.useState(comingListWithDates);
  const isSelected = name => selected.indexOf(name) !== -1;


  useEffect(() => {
    const reservationInterval = setInterval(() => reload(`/api/reservation/active`), 30000);
    return () => {
      clearInterval(reservationInterval);
    };
  }, []);
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'asc';
    setOrder(isDesc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  useEffect(() => {
    const newReservationsList = [...comingReservationsList];
    newReservationsList.forEach((reservation) => {
      const visitAmount = reservation.reservationVisitingTimes.length;
      const firstDate = reservation.reservationVisitingTimes[0]?.visitingDate;
      const lastDate = moment(reservation.reservationVisitingTimes[visitAmount - 1]?.visitingDate).format(DATE);
      reservation.reserver = reservation.reservationContacts[0]?.organization;
      reservation.pipedrive = reservation.lastModifiedBy === "Kauppaneuvos";
      reservation.visitingDate = moment(firstDate);
      if (visitAmount > 1) {
        reservation.visitingDateLabel = moment(firstDate).format(FIRST_DATE) + "-" + lastDate + " (" + visitAmount + ")";
      } else {
        reservation.visitingDateLabel = lastDate;
      }
    });
    setComingListWithDates(newReservationsList);
    setPage(0);
  }, [comingReservationsList]);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  useEffect(() => {
    setEmptyRows(rowsPerPage - Math.min(rowsPerPage, comingReservationsList.length - page * rowsPerPage));
  }, [rowsPerPage, comingReservationsList, page]);

  useEffect(() => {
    setFilteredList(comingListWithDates.filter( reservation => reservation.reservationName.toUpperCase().includes( searchValue.toUpperCase() ) || reservation.reserver?.toUpperCase().includes( searchValue.toUpperCase() ) ));
  }, [searchValue, comingListWithDates]);

  return (
    <div style={{ border: "solid 0.5px #f2f2f2" }}>
      <IndexedSearch classes={classes} setReservations={setComingReservationsList} past={false}/>

      <div className={classes.searchContainer} >
        <Search searchValue={searchValue}
                setSearchValue={setSearchValue} />
      </div>

      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            headRows={headRows}
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filteredList.length}
          />
          <TableBody>
            {stableSort(filteredList, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    onClick={() => {
                      let newWindow = window.open('','_blank');
                      newWindow.location.href = reservation + `/`+ row.id;
                    }}
                    className="pointer"
                  >
                    <TableCell>
                      {row.reservationName}
                    </TableCell>
                    <TableCell>
                      {row.reserver}
                    </TableCell>
                    <TableCell>
                      {row.reservationSize}
                    </TableCell>
                    <TableCell>
                      <Icons productData={productData} reservation={row} />
                    </TableCell>
                    <TableCell>
                      {row.visitingDateLabel}
                    </TableCell>
                    <TableCell style={{paddingTop: "10px", paddingBottom: "10px"}}>
                      {
                        row.lastModifiedBy === "Kauppaneuvos"
                          ?
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ width: 25, height: "100%" }} />
                          :
                            ""
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[10, 15, 20]}
        component="div"
        count={filteredList.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={<FormattedMessage id={messages.rowsPerPage}/>}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
