import React from 'react';
import { FormattedMessage } from 'react-intl';
import RTE from "../../../shared/RTE";
import messages from 'intl/messages.properties';
import { isEmpty } from 'lodash';
import * as PropTypes from "prop-types";

import CustomSnackbar from "../../../shared/CustomSnackbar";
import productStyle from '../../../styles/FormStyle';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave} from "@fortawesome/free-solid-svg-icons/index";
import useForm from "react-hook-form";
import {Alert} from "react-bootstrap";
import {useUser} from "@postinumero/authorization";
import DeleteDialogue from "./DeleteDialogue";

const useStyles = makeStyles(productStyle);

function Form(props) {

  const classes = useStyles();
  const user = useUser();


  // checks, if the newsletter is a new one or if the user will be the publisher
  let publisher = "";
  try {
    publisher = props.data.writerName;
  } catch (e) {
    publisher = user.name;
  }

  const isOnEditPage = Boolean(props.data);

  // data, newsletter's title, body text, expiration date and the publisher
  const defaultValues = props.data ?? {
    title: '',
    bodyText: '',
    expirationDate: ''
  };

  const { errors, register, handleSubmit, setError, clearError, setValue, watch } = useForm({
    mode: 'onSubmit',
    defaultValues
  });
  const bodyText = watch('bodyText');
  const expirationDate = watch('expirationDate');

  const [success, setSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const closeSnackbar = (event, reason) => {
    // Do not close snackbars on blur
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setOpenError(false);
  };


  const onSubmit = async (data, event) => {
    event.preventDefault();

    const submittedBodyText = bodyText.replace(/* zero width space */ /[​↵]/g, '');

    if (!submittedBodyText || (submittedBodyText.charCodeAt(0) === 10 && submittedBodyText.length < 2)) {
      setError(
        'bodyText', undefined, messages.newsletterBodyRequired
      )
      return;
    }

    if (Object.entries(errors).length > 0) {
      return;
    }

    let expDate = expirationDate;
    if (expirationDate === "") {
      expDate = null;
    }

    if (isOnEditPage) {
      if (await props.save({
        ...data,
        id: props.data.id,
        writerName: publisher,
        bodyText: submittedBodyText,
        expirationDate: expDate,
        createdTimeStamp: props.data.createdTimestamp,
        updatedTimeStamp: props.data.updatedTimestamp
      })) {
        setSuccess(true);
        setOpenError(false);
      } else {
        setOpenError(true);
        setSuccess(false);
      }
    }
    else {
      if (await props.save({
        ...data,
        id: undefined,
        writerName: publisher,
        bodyText: submittedBodyText,
        expirationDate: expDate,
        createdTimeStamp: undefined,
        updatedTimeStamp: undefined
      })) {
        setSuccess(true);
        setOpenError(false);
      } else {
        setOpenError(true);
        setSuccess(false);
      }
    }
  };

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <CustomSnackbar
        open={success}
        onClose={closeSnackbar}
        type="success"
        message={<FormattedMessage id={messages.saveSuccess} />}
      />
      <CustomSnackbar
        open={openError}
        onClose={closeSnackbar}
        type="error"
        message={<FormattedMessage id={messages.saveError} />}
      />
      <Paper className={classes.paper}>
        {!isEmpty(errors) && Object.values(errors).map((error, index) => {
            return <Alert key={index} variant="danger">
              <FormattedMessage id={error.message} />
            </Alert>
          }
        )}
        <div>
          <div>
            <FormControl className={classes.formControl}>
              <label className={classes.label}>
                <FormattedMessage id={messages.newsletterAddTitle} />
              </label>
              <TextField
                id="title"
                name="title"
                className={classes.textField}
                variant="filled"
                inputRef={register({
                  required: messages.newsletterTitleRequired
                })}
                error={errors.title}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <label className={classes.label}>
                <FormattedMessage id={messages.newsletterAddName} />
              </label>
              <div style={{ marginTop: -10 }}>
                {publisher}
              </div>
            </FormControl>

            <FormControl className={classes.formControl}>
              <label className={classes.label}>
                <FormattedMessage id={messages.newsletterAddBodyText} />
              </label>
              <RTE
                value={bodyText}
                ref={register({
                  name:'bodyText'
                })}
                setValue={bodyText => {
                  setValue('bodyText', bodyText);
                   clearError('bodyText');

                }}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <label className={classes.label}>
                <FormattedMessage id={messages.newsletterAddExpiration} />
              </label>
              <TextField
                id="expirationDate"
                className={classes.dateField}
                type="date"
                value={expirationDate}
                variant="filled"
                name="expirationDate"
                inputRef={register}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </div>
        </div>
      </Paper>
      <FormControl className={classes.formControlButtons}>

        {
          isOnEditPage
            ? <div className="delete-button">
                <DeleteDialogue id={props.data.id} />
              </div>
            : null
        }
        <Button variant="contained"
                type="submit"
                className={classes.greenButton}
        >
          <FontAwesomeIcon icon={faSave}
                           style={{marginRight: 10}}
          />
          <FormattedMessage id={messages.save} />
        </Button>
      </FormControl>
    </form>
  );
}

Form.propTypes = {
  data: PropTypes.object,
  created: PropTypes.bool,
  save: PropTypes.func.isRequired
};

export default Form;
