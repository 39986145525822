const formStyle = theme => ({
  form: {
    margin: 5,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header3: {
    margin: "20px 0",
  },
  formTitle: {
    margin: "20px 5px",
  },
  reservationContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  appBar: {
    boxShadow: "none",
  },
  appBarTab: {
    minWidth: 120,
  },
  tabPanel: {
    padding: "20px 0 10px",
  },
  appBarReservations: {
    justifyContent: "space-around",
    boxShadow: "none",
  },
  paper: {
    padding: "20px 10px",
  },
  paperSplit30: {
    padding: 10,
    width: "35%",
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginRight: 0,
      marginBottom: 10,
    },

  },
  paperSplit70: {
    padding: 10,
    width: "65%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  paperSplit50: {
    padding: 10,
    width: "50%",
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  reservationContent: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    margin: "10px 5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  formControlVisitingTime: {
    margin: "10px 5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  formControlColorPicker: {
    margin: "10px 5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    '& input': {
      paddingLeft: 5,
      color: "#1a1a1a",
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  formControlMultiline: {
    margin: "10px 5px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  selectControl: {
    margin: "0 15px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  formControlIcon: {
    margin: "10px 5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "baseline",
    },
  },
  formControlButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    margin: "10px 0",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "flex-end",
    }
  },
  label: {
    marginRight: 10,
    maxWidth: 130,
    width: "100%",
    whiteSpace: "normal",
    input: {
      marginTop: 0,
    }
  },
  textField: {
    width: "100%",
    maxHeight: 30,
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
    },
  },
  autocompleteInput: {
    color: "white",
    borderColor: "white"
  },
  autoCompleteRow: {
    height: "100%",
    width: "100%",
    display: "table"
  },
  autoCompleteRowName: {
    height: "100%",
    width: "80%"
  },
  autoCompleteRowIcon: {
    width: "20%",
    height: "100%",
    display: "table-cell",
    verticalAlign: "middle"
  },
  multilineTextField: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
    },
    '& textarea': {
      minHeight: 150,
    }
  },
  textAreaField: {
    width: "100%",
    margin: "5px 0",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
    },
  },
  numberField: {
    maxWidth: 150,
    marginRight: 5,
  },
  dateField: {
    maxWidth: 150,
    marginRight: 5,
  },
  visitingTimedateField: {
    maxWidth: 150,
    margin: " 0 5px 0 0",
  },
  timeField: {
    maxWidth: 120,
  },
  select: {
    minWidth: 130,
    marginTop: "0 !important",
  },
  gustafIcon: {
    width: "40px !important",
    height: "40px !important",
    padding: 8,
    margin: "10px 20px 10px 0",
    borderRadius: "100%",
    fontSize: 30,
    background: "#6f6f6f",
    '& path': {
      fill: "#FFF !important"
    }
  },
  gostaIcon: {
    width: "40px !important",
    height: "40px !important",
    padding: 8,
    margin: "10px 20px 10px 0",
    borderRadius: "100%",
    fontSize: 30,
  },
  button: {
    margin: "15px 0 5px",
    float: "right",

    [theme.breakpoints.down('md')]: {
      fontSize: "0.85em"
    }
  },
  childFields: {
    margin: "15px 5px 20px",
    marginLeft: 150,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      flexDirection: "column",
      alignItems: "start",
    }
  },
  divVisitingTime: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 145,
    [theme.breakpoints.down('md')]: {
      marginLeft: 5,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
  },
  divStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 145,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
    },
  },
  divMuseum: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      margin: 5,
    },
  },
  reservationChildFields: {
    margin: "5px",
    marginLeft: 150,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      flexDirection: "column",
      alignItems: "start",
    }
  },
  childFieldLabel: {
    marginBottom: 0,
    minWidth: 100,
  },
  multilineLabel: {
    marginBottom: 0,
    maxWidth: 130,
    width: "100%",
    whiteSpace: "normal",
  },
  formActionButton: {
    margin: "5px 10px 5px 0",
    borderCollapse: "collapse",
    [theme.breakpoints.down('sm')]: {
      margin: "5px 0"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "0.85em"
    }
  },
  greenButton: {
    margin: "5px 0",
    backgroundColor: "#068A3F",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#067a3d",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "0.85em"
    }
  },
  redButton: {
    margin: "5px 10px 5px 0",
    backgroundColor: "#df3008",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#a50000",
    },
    [theme.breakpoints.down('sm')]: {
      margin: "5px 0"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "0.85em"
    }
  },
  split: {
    width: "50%",
    padding: "0 5px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
    },
  },
  split60 : {
    width: "60%",
    padding: "0 5px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
      marginBottom: 20,
    },
  },
  split40 : {
    width: "40%",
    padding: "0 5px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
    },
  },
  split70: {
    width: "70%",
    padding: "0 5px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
    },
  },
  split30: {
    width: "30%",
    padding: "0 5px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
    },
  },
  fullWidth : {
    width: "100%",
    padding: "0 5px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
    },
  },
  checkboxClass: {
    padding: "0 5px",
  },
  formList: {
    width: "100%",
    margin: 0,
    padding: 0,
    background: "#f2f2f2",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
      margin: 0,
      padding: 20,
    },
  },
  menuItem: {
    minHeight: "100%",
  },
  qualificationList: {
    padding: "10px 5px",
    background: "#f2f2f2",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  qualificationItem: {
    padding: 5,
  },
  qualificationLanguages: {
    '& span': {
      marginRight: 10,
    }
  },
  editQualificationsButton: {
    margin: "20px 10px 0",
    [theme.breakpoints.down('md')]: {
      fontSize: "0.85em"
    }
  },
  input: {
    display: 'none',
  },
  documentationTextField : {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxHeight: "100%",
  },
  emailFormControl: {
    flexDirection: "row",
    alignItems: "center",
  },
  actionButton: {
    padding: "4px 6px",
    margin: "0 5px",
    fontSize: "0.9em !important"
  },
  tableCell: {
    padding: "2px 24px 2px 16px",
  },
  printButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "flex-end",
      fontSize: "0.9em"
    }
  },
  notesTextField: {
    width: "100%",
    minHeight: "50vh",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
    },
    '& textarea': {
      minHeight: "50vh",
    }
  },
  dialogContent: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  dialogActions: {
    margin: 10,
    justifyContent: "space-between"
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }
});


export default formStyle;
