const reservedResourceStatus = {

  ACCEPTED: "Hyväksynyt",
  DENIED: "Perunut",
  PENDING: "Odottaa",
  NOT_REQUESTED: "--"

};

export default reservedResourceStatus;
