import React, {useEffect} from 'react';
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import TabPanel from '../../../shared/TabPanel';
import {FormattedMessage} from "react-intl";
import messages from 'intl/messages.properties';
import ReminderHead from "./Reminders";
import useUser from "@postinumero/authorization/useUser";
import Authorize from "@postinumero/authorization/Authorize";
import moment from "moment";
import LastModifiedHead from "./LastModified";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    margin: "15px 0 0",
    boxShadow: "none",
  },
  tabPanel: {
    padding: "20px 0 10px",
  },
}));

export default function Reminder({ reservationData, productData, resourceData }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [userId, setUserId] = React.useState();
  const [shownReminders, setShownReminders] = React.useState([]);
  const [shownEdits, setShownEdits] = React.useState([]);
  const user = useUser();

  useEffect(() => {
    const resource = resourceData.find(resource => resource.keycloakId === user.id);
    if (resource) {
      setUserId(resource.id);
    }
  }, [resourceData, user]);

  useEffect(() => {
    // CREATING REMINDERS LIST
    const newReminders = [];
    for (let i = 0; i < reservationData.length; i++) {
      for (let j = 0; j < reservationData[i].accountableResources.length; j++) {
        if (reservationData[i].accountableResources[j] === userId && moment(reservationData[i].reactionToReservation).set("h", 1).isBetween(moment().set("h", 0), moment().add(1, "M"))) {
          newReminders.push(reservationData[i]);
        }
      }
    }
    setShownReminders(newReminders);
  }, [reservationData, userId]);

  useEffect(() => {
    // CREATING LAST EDITED LIST
    const newEdits = [];
    for (let i = 0; i < reservationData.length; i++) {
      if (reservationData[i].lastModifiedBy === user.id) {
        newEdits.push(reservationData[i]);
      }
    }
    newEdits.sort((prev, next) => moment(prev.reservationVisitingTimes[0].visitingDate).format("YYYYMMDD") > moment(next.reservationVisitingTimes[0].visitingDate).format("YYYYMMDD"));
    setShownEdits(newEdits);
  }, [reservationData, user]);


  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static"
              className={classes.appBar}
      >
        <Authorize allow="Asiakaspalvelu">
          <Tabs value={value} onChange={handleChange} aria-label="">
            <Tab label={<FormattedMessage id={messages.reminderTitle} />}
                 className={classes.appBarTab}
                 {...a11yProps(0)}
            />
              <Tab
                label={<FormattedMessage id={messages.recentEditsTitle} />}
                className={classes.appBarTab}
                {...a11yProps(1)}
              />
          </Tabs>
        </Authorize>
      </AppBar>
      <TabPanel value={value}
                index={0}
      >
        {
            shownReminders.length > 0
              ?
                <ReminderHead
                  shownReminders={shownReminders}
                  resourceData={resourceData}
                  productData={productData}
                />
              :
              <div
                style={{
                  padding: "15px 10px"
                }}
              >
                <FormattedMessage id={messages.reminderNone}/>
              </div>
        }
      </TabPanel>
      <TabPanel value={value}
                index={1}
      >
        {
          shownEdits.length > 0
            ?
            <LastModifiedHead
              shownEdits={shownEdits}
              resourceData={resourceData}
              productData={productData}
            />
            :
            <div
              style={{
              padding: "15px 10px"
            }}
            >
              <FormattedMessage id={messages.recentEditsNone}/>
            </div>
        }
      </TabPanel>
    </div>
  );
}
