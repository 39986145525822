import React from 'react';
import TabPanel from '../../shared/TabPanel';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';
import { Suspense } from 'react';
import { Link } from 'react-router-dom';

import Spinner from 'Spinner';
import Page from 'Page';
import List from './List';
import PastList from './PastList';

import {
  makeStyles,
  Box,
  Paper,
  Button,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import {reservation} from "../Reservations/Reservation/path";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus} from "@fortawesome/free-solid-svg-icons/index";
import Authorize from "@postinumero/authorization/Authorize";
import useAxios from "use-axios";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  greenButton: {
    margin: "0 5px",
    float: "right",
    backgroundColor: "#068a3f",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#067a3d",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "0.9em"
    }
  },
  title: {
    margin: "10px 15px",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

function Reservations() {
  const classes = useStyles();

  const [activeTab, setActiveTab] = React.useState(0);

  const {data: productData} = useAxios(`/api/product`);

  function handleTabChange(event, newTab) {
    setActiveTab(newTab);
  }

  return (
      <Page>
        <Suspense fallback={<Spinner />}>
          <Paper display="flex"
                 style={{
                   padding: "10px",
                   margin: "10px 0"
                 }}>
            <Box display="flex"
                 className={classes.title}>
              <Page.Title>
                <FormattedMessage id={messages.reservations} />
              </Page.Title>
              <Authorize allow="Asiakaspalvelu">
                <Link to={reservation+"/new"}>
                  <Button variant="contained"
                          className={classes.greenButton}>
                    <FontAwesomeIcon icon={faPlus}
                                     style={{marginRight: 10}}/>
                    <FormattedMessage id={messages.newReservation} />
                  </Button>
                </Link>
              </Authorize>
            </Box>

            <AppBar position="static"
                    className={classes.appBar}
                    style={{
                      boxShadow: "none"
                    }}>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="">
                <Tab label="Varaukset"
                     className={classes.appBarTab}
                     {...a11yProps(0)} />
                <Tab label="Menneet varaukset"
                     className={classes.appBarTab}
                     {...a11yProps(1)} />
              </Tabs>
            </AppBar>

            <TabPanel value={activeTab}
                      index={0}
                      style={{ paddingTop: 0 }}>
              <List  productData={productData}/>
            </TabPanel>
            <TabPanel value={activeTab}
                      index={1}
                      style={{ paddingTop: 0 }}>
              <Suspense fallback={<Spinner/>}>
                <PastList productData={productData}/>
              </Suspense>
            </TabPanel>

          </Paper>
        </Suspense>
      </Page>
  )
}
export default Reservations;
