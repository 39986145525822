import React, { Suspense } from 'react';
import Spinner from 'Spinner';
import Newsletter from './Newsletter';
import AddNewsletter from './Newsletter/AddNewsletter';
import EditNewsletter from './Newsletter/EditNewsletter';
import ErrorBoundary from "react-error-boundary";
import NotFound from "NotFound";
import { Redirect, Route, Switch } from "react-router-dom";
import RequestErrorBoundary from "../../shared/RequestErrorBoundary";
import newsletter, { addNewsletter, editNewsletter } from "./path";

function NewsLetters({ match }) {

  return (
    <Suspense fallback={<Spinner />}>
      <ErrorBoundary FallbackComponent={NotFound}>
        <RequestErrorBoundary>
          <Switch>
            <Route path={addNewsletter} component={AddNewsletter} />
            <Route path={newsletter} exact component={Newsletter} newsletterId={match.params.id} />
            <Route path={editNewsletter} exact component={EditNewsletter} newsletterId={match.params.id} />
            <Redirect to={newsletter} />
          </Switch>
        </RequestErrorBoundary>
      </ErrorBoundary>
    </Suspense>
  )
}

export default NewsLetters;
