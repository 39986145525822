import parent from '../../path';

export const reservation = `${parent}/reservation`;

export const newReservation = `${parent}/reservation/new`;

export const matchReservation = `${parent}/reservation/:id`;

export const editOfferBase = `${parent}/reservation/editOffer/:id`;

export default reservation;
