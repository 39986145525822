const drawerWidth = 180;
const appBarHeight = 64;

const appBarStyle = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: appBarHeight,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      marginTop: appBarHeight,
    },
    width: drawerWidth,
    flexShrink: 0,
  },
  image: {
    width: "100%",
    position: "absolute",
    bottom: 80
  }
})

export default appBarStyle;
