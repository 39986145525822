import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import { uniq } from 'lodash';
import * as icons from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";
import listStyle from "../../styles/ListStyle";

const iconsArray = Object.entries(icons).map( icon => {
  return  icon.iconName !== "fas" ? icon : ''
});

const useStyles = makeStyles(listStyle);

function Icons({ productData, reservation }) {
  const classes = useStyles();

  const productIds = reservation.reservationGroups
    .flatMap(reservationGroup => reservationGroup.reservationGroupProgramItems
      .map(item => item.productId));

  const uniqProductIds = uniq(productIds);

  const iconTypes = uniqProductIds
    .map(productId => {
        let product = productData.find(product => product.id === productId);
        if (!product || !product.productIcon) {
          return [];
        }
        return product.productIcon.split('|');
      }
    );

  if (!iconTypes || !Array.isArray(iconTypes)) {
    return;
  }



  try {
    return iconTypes
      .map((icon,index) => {
          const [iconClass,name] = icon;
          const [, faIcon] = iconsArray.find(([iconName]) => name === iconName);

          const iconClassName = iconClass
            ? `${iconClass}Icon`
            : '';
          return  <FontAwesomeIcon key={name+index}
                                   icon={faIcon}
                                   className={classes[iconClassName]}/>
        }
      );
  } catch (e) {
    return <></>;
  }
}

export default Icons;
