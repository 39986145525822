import React, {useEffect} from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';
import { camelCase, isEmpty } from 'lodash';
import * as PropTypes from "prop-types";


import CustomSnackbar from "../../../shared/CustomSnackbar";
import IconDialog from './IconDialog';
import productStyle from '../../../styles/FormStyle';
import productTypes from '../../../shared/definitions/productTypes';
import iconsArray from '../../../shared/definitions/iconsArray';
import FormActionButtons from "../../../shared/FormActionButtons";

import {
  makeStyles,
  Paper,
  Button,
  MenuItem,
  FormControl,
  FormControlLabel,
  Select,
  TextField,
  Checkbox
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useForm from "react-hook-form";
import {Alert} from "react-bootstrap";
import MomentUtils from "@date-io/moment";
import useAuthorize from "@postinumero/authorization/useAuthorize";

const useStyles = makeStyles(productStyle);
const moment = require('moment');

function Form(props) {
  const classes = useStyles();
  const isMyyntipalvelu = useAuthorize({ allow: "Myyntipalvelu" });

  const initialData = {
    productType: 'GO_OPASTUKSET',
    status: 'ACTIVE',
    statusStart: null,
    statusEnd: null,
    name: '',
    length: '',
    description: '',
    pricingInformation: '',
    additionalInfo: '',
    limited: false,
    maxReservations: '',
    maxGroupSize: '',
    maxGroupSizeTotal: '',
    productIcon: ''
  };

  const { data = initialData, created = false } = props;

  const { errors, setError, register, handleSubmit, setValue, watch, reset } = useForm({
    mode: 'onBlur',
    defaultValues: data
  });

  useEffect(() => {
    register({ name: "id" });
    register({ name: "status" });
    register({ name: "statusStart" });
    register({ name: "statusEnd" });
    register({ name: "productType" });
    register({ name: "productIcon" });
    register({ name: "museumType" });
    register({ name: "limited" })
  }, [register]);

  const statusWatch = watch("status");
  const productTypeWatch = watch("productType");
  const limitedWatch = watch("limited");
  const productIconWatch = watch("productIcon");
  const statusStartWatch = watch("statusStart");
  const statusEndWatch = watch("statusEnd");

  const [disabled, setDisabled] = React.useState(false);
  const [success, setSuccess] = React.useState(created);
  const [copySuccess, setCopySuccess] = React.useState(created);
  const [museumType, setMuseumType] = React.useState(productIconWatch?.split("|")[0]);
  const [saveError, setSaveError] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  function handleClickOpen() {
    setDialogOpen(true);
  }

  const handleClose = value => {
    setDialogOpen(false);
    setValue("productIcon", value);
  };

  function handleManualValueChange(event) {
    setValue(event.target.name, event.target.value);
  }

  function handleManualCheckChange(event) {
    setValue(event.target.name, event.target.checked);
  }

  function handleMuseumTypeCheckChange(event) {
    setMuseumType(event.target.checked ? event.target.name : "");
  }

  const handleItemDateChange = statusName => date => {
    const dateValue = moment(date);;
    setValue(statusName, dateValue.format("YYYY-MM-DD"));
  };

  const closeSnackbar = (event, reason) => {
    // Do not close snackbars on blur
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setSaveError(false);
  };

  const onSubmit = async (data, event) => {
    if (productIconWatch === undefined || productIconWatch === null || productIconWatch === "") {
      setError("productIcon", "required", messages.productIconRequired);
      return;
    }
    setDisabled(true)
    event.preventDefault();
    if (Object.entries(errors).length > 0) {
      return;
    }

    if (await props.save(data)) {
      setDisabled(false)
      setSuccess(true);
      setSaveError(false);
    } else {
      setDisabled(false)
      setSaveError(true);
      setSuccess(false);
    }
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <CustomSnackbar
          open={success}
          onClose={closeSnackbar}
          type="success"
          message={<FormattedMessage id={messages.saveSuccess} />}
        />
        <CustomSnackbar
          open={saveError}
          onClose={closeSnackbar}
          type="error"
          message={<FormattedMessage id={messages.saveError} />}
        />
        <CustomSnackbar
          open={copySuccess}
          onClose={closeSnackbar}
          type="success"
          message={<FormattedMessage id={messages.copySuccess}/>}
        />

        <FormActionButtons allow="Myyntipalvelu"
                           disabled={disabled}
                           values={data}
                           classes={classes}
                           remove={props.remove}
                           copy={props.copy}
                           reset={reset}
                           setCopySuccess={setCopySuccess}
        />

        <Paper className={classes.paper}>
          {!isEmpty(errors) && Object.values(errors).map((error, index) => {
              return <Alert key={index} variant="danger">
                <FormattedMessage id={error.message} />
              </Alert>
            }
          )}
          <div className={classes.content}>
            <div className={classes.split}>
              <FormControl required className={classes.formControl}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.productType}/>
                </label>
                <Select
                  id="productType"
                  name="productType"
                  value={productTypeWatch}
                  disabled={!isMyyntipalvelu}
                  className={classes.select}
                  variant="filled"
                  onChange={handleManualValueChange}
                >
                  {productTypes.map( (type, index) =>
                    <MenuItem key={index}
                              value={type}>
                      <FormattedMessage id={messages[camelCase(type)]} />
                    </MenuItem>
                  )}
                </Select>

              </FormControl>

              <FormControl className={classes.formControl}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.productName} />
                  *
                </label>
                <TextField
                  id="name"
                  name="name"
                  className={classes.textField}
                  disabled={!isMyyntipalvelu}
                  variant="filled"
                  inputRef={register({
                    required: messages.productNameRequired
                  })}
                  error={errors.name}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.length} />
                  *
                </label>
                <TextField
                  id="length"
                  name="length"
                  disabled={!isMyyntipalvelu}
                  inputRef={register({
                    required: messages.productLengthRequired
                  })}
                  type="number"
                  className={classes.numberField}
                  variant="filled"
                  error={errors.length}
                />
                min
              </FormControl>

              <FormControl className={classes.formControlMultiline}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.description} />
                </label>
                <TextField
                  id="description"
                  name="description"
                  disabled={!isMyyntipalvelu}
                  inputRef={register}
                  className={classes.textAreaField}
                  type="string"
                  multiline
                  rows="12"
                  variant="filled"
                  style={{ minHeight: 100 }}
                />
              </FormControl>

              <FormControl className={classes.formControlMultiline}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.pricingInformation} />
                </label>
                <TextField
                  id="pricingInformation"
                  name="pricingInformation"
                  disabled={!isMyyntipalvelu}
                  inputRef={register}
                  className={classes.textAreaField}
                  type="string"
                  multiline
                  rows="4"
                  variant="filled"
                  style={{ minHeight: 100 }}
                />
              </FormControl>

              <FormControl required className={classes.formControl}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.status} />
                </label>

                <Select
                  onChange={handleManualValueChange}
                  disabled={!isMyyntipalvelu}
                  className={classes.select}
                  value={statusWatch}
                  inputProps={{
                    name: 'status',
                    id: 'status',
                  }}>
                  <MenuItem value={"ACTIVE"}>
                    <FormattedMessage id={messages.active} />
                  </MenuItem>
                  <MenuItem value={"PASSIVE"}>
                    <FormattedMessage id={messages.passive} />
                  </MenuItem>
                </Select>

              </FormControl>
              <div className={classes.divStatus}>
                { statusWatch !== '' ?
                  <>
                    <KeyboardDatePicker
                      disableToolbar
                      disabled={!isMyyntipalvelu}
                      variant="inline"
                      inputVariant="filled"
                      format="DD.MM.YYYY"
                      margin="normal"
                      className={classes.dateField}
                      style={{ marginTop: 0 }}
                      value={statusStartWatch}
                      onChange={handleItemDateChange("statusStart")}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <span style={{ margin: "0 10px" }}> - </span>
                    <KeyboardDatePicker
                      disableToolbar
                      disabled={!isMyyntipalvelu}
                      variant="inline"
                      inputVariant="filled"
                      format="DD.MM.YYYY"
                      margin="normal"
                      className={classes.dateField}
                      style={{ marginTop: 0 }}
                      value={statusEndWatch}
                      onChange={handleItemDateChange("statusEnd")}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </>
                  : ''
                }
              </div>

              <FormControl className={classes.formControl}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.limited} />
                </label>
                <Checkbox id='limited'
                          name='limited'
                          onChange={handleManualCheckChange}
                          disabled={!isMyyntipalvelu}
                          checked={limitedWatch}
                          className={classes.checkboxClass}/>
              </FormControl>
              { limitedWatch ?
                <>
                  <FormControl className={classes.childFields}>
                    <label className={classes.label}  style={{minWidth: 200}}>
                      <FormattedMessage id={messages.maxReservations} />
                    </label>
                    <TextField
                      id="maxReservations"
                      name="maxReservations"
                      disabled={!isMyyntipalvelu}
                      inputRef={register}
                      className={classes.numberField}
                      type="number"
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl className={classes.childFields}>
                    <label className={classes.label} style={{minWidth: 200}}>
                      <FormattedMessage id={messages.maxGroupSize} />
                    </label>
                    <TextField
                      id="maxGroupSize"
                      name="maxGroupSize"
                      disabled={!isMyyntipalvelu}
                      inputRef={register}
                      className={classes.numberField}
                      type="number"
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl className={classes.childFields}>
                    <label className={classes.label} style={{minWidth: 200}}>
                      <FormattedMessage id={messages.maxGroupSizeTotal} />
                    </label>
                    <TextField
                      id="maxGroupSizeTotal"
                      name="maxGroupSizeTotal"
                      disabled={!isMyyntipalvelu}
                      inputRef={register}
                      className={classes.numberField}
                      type="number"
                      variant="filled"
                    />
                  </FormControl>
                </>
                : ''}

              <div>
                <FormControl className={classes.formControl}>
                  <label className={classes.label}>
                    <FormattedMessage id={messages.productIcon} />
                  </label>
                    <div className={classes.divMuseum}>
                    <FormControlLabel className={classes.childFieldLabel}
                                      control={ <>
                                        <Checkbox id={'gosta'}
                                                  name='gosta'
                                                  disabled={!isMyyntipalvelu}
                                                  checked={museumType === 'gosta'}
                                                  onChange={handleMuseumTypeCheckChange}
                                                  className={classes.checkboxClass}/>
                                      </>}
                                      labelPlacement="end"
                                      label={<FormattedMessage id={messages.gosta}/>}
                    />

                    <FormControlLabel className={classes.childFieldLabel}
                                      control={ <>
                                        <Checkbox id={'gustaf'}
                                                  name='gustaf'
                                                  disabled={!isMyyntipalvelu}
                                                  checked={museumType === 'gustaf'}
                                                  onChange={handleMuseumTypeCheckChange}
                                                  className={classes.checkboxClass}/>
                                      </>}
                                      labelPlacement="end"
                                      label={<FormattedMessage id={messages.gustaf}/>}
                    />
                  </div>
                </FormControl>
              </div>

              <FormControl className={classes.childFields}>
                {productIconWatch ? iconsArray.map(([name, icon]) => {
                  let iconClassName = productIconWatch !== undefined
                    ?`${productIconWatch.split('|')[0]}Icon`
                    : '';

                  return name === productIconWatch.split('|')[1]  ?
                    <FontAwesomeIcon key={name}
                                     icon={icon}
                                     className={classes[iconClassName]}/> : ''
                }): ''}
                <Button variant="contained"
                        onClick={handleClickOpen}
                        disabled={!useAuthorize({ allow: "Myyntipalvelu" }) || isEmpty(museumType)}
                >
                  <FormattedMessage id={messages.choose} />
                </Button>
                <div>
                  <IconDialog selectedValue={productIconWatch}
                              open={dialogOpen}
                              onClose={handleClose}
                              selectedMuseum={museumType}
                  />
                </div>
              </FormControl>
            </div>

            <div className={classes.split}>
              <FormControl className={classes.formControlMultiline}>
                <label className={classes.label}>
                  <FormattedMessage id={messages.additionalInfo} />
                </label>
                <TextField
                  id="additionalInfo"
                  name="additionalInfo"
                  inputRef={register}
                  disabled={!isMyyntipalvelu}
                  className={classes.textAreaField}
                  type="string"
                  multiline
                  rows="4"
                  variant="filled"
                  style={{ minHeight: 100 }}
                />
              </FormControl>
            </div>

          </div>
        </Paper>
        <FormActionButtons allow="Myyntipalvelu"
                           disabled={disabled}
                           values={data}
                           classes={classes}
                           remove={props.remove}
                           copy={props.copy}/>
      </form>
    </MuiPickersUtilsProvider>
  </>
  );
}

Form.propTypes = {
  data: PropTypes.object,
  created: PropTypes.bool,
  save: PropTypes.func.isRequired
};

export default Form;
