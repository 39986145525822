import React, { Suspense } from 'react';

import Spinner from 'Spinner';
import Page from 'Page';
import Forbidden from 'Forbidden';

import SettingsContent from './SettingsContent';
import Authorize from "@postinumero/authorization/Authorize";


function Settings() {

  return (
    <Authorize allow="Myyntipalvelu" fallback={<Forbidden />}>
      <Page>
          <Suspense fallback={<Spinner />}>
            <SettingsContent/>
          </Suspense>

      </Page>
    </Authorize>
  )
}
export default Settings;
