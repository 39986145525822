import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';

import TabPanel from "../../shared/TabPanel";

import {
  makeStyles,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';

import OfferBases from './OfferBases';
import formStyle from "../../styles/FormStyle";
import EditOfferBase from "../Reservations/Reservation/FormSections/SendingInfoActions/EditOfferBase";
import Indexing from "./Indexing";

const useStyles = makeStyles(formStyle);

export default function SettingsContent() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [editingIndex] = React.useState(-1);

  function handleTabChange(event, newValue) {
    setValue(newValue);
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (<>
    <Paper className={classes.paper}
           style={{ width: "100%" }}>
      <Box display="flex"
           style={{
             margin: "0 15px 10px",
             justifyContent: "space-between",
             alignItems: "center"
           }}>
        <h1>
          <FormattedMessage id={messages.settings} />
        </h1>
      </Box>
      { editingIndex === -1 ?
        <>
      <AppBar position="static"
              className={classes.appBarReservations}>
        <Tabs value={value}
              onChange={handleTabChange}
              aria-label="">
          <Tab label={<FormattedMessage id={messages.offerBases} />}
               className={classes.appBarTab}
               {...a11yProps(0)} />
          <Tab label={<FormattedMessage id={messages.maintenance} />}
               className={classes.appBarTab}
               {...a11yProps(1)} />
        </Tabs>
      </AppBar>
        <TabPanel value={value}
                  index={0}>
          <OfferBases/>
        </TabPanel>
        <TabPanel index={value} value={1}>
          <Indexing />
        </TabPanel>
        </>
        : <EditOfferBase
          />
      }

      </Paper>
    </>
  )

}
