import React from 'react';
import {FormattedMessage} from "react-intl";
import messages from 'intl/messages.properties';
import * as PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/index";

export default function DeleteDialog(props) {
  const { data, remove, classes, open, setOpen} = props;

  function handleClose() {
    setOpen(false);
  }

  function handleRemove() {
    remove(data);
    setOpen(false);
  }

  const handleRemoveMessage = () => {
    if(!data) {
      return data
    } else {
      return data.name
        ? data.name
        : data.fullName
          ? data.fullName
          : data.reservationName
            ? data.reservationName
            : ''
    }
  };

  return (
    <Dialog open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id={messages.confirmModalTitleRemoveItem}/>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id={messages.confirmModalBodyRemoveItem}
                            values={{name: handleRemoveMessage()}}/>
        </DialogContentText>
      </DialogContent>

      <Divider/>

      <DialogActions>
        <Button onClick={handleClose}
                color="primary"
                style={{ margin: "5px 10px 5px 5px" }}>
          <FormattedMessage id={messages.cancel}/>
        </Button>
        <Button onClick={() => handleRemove()}
                className={classes.redButton}
                style={{ margin: "5px 0 5px 5px" }}>
          <FontAwesomeIcon icon={ faTrashAlt }
                           style={{marginRight: 10}} />
          <FormattedMessage id={messages.remove}/>
        </Button>
      </DialogActions>
    </Dialog>
  );
}


DeleteDialog.propTypes = {
  data: PropTypes.object,
  remove: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};
