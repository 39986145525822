const productTypes = [
  "GO_OPASTUKSET",
  "GO_TILAVUOKRAUS",
  "GO_TILAVARAUS",
  "GO_RAVINTOLA",
  "GO_KOULULAIS",
  "GO_MUUT",
  "GU_OPASTUKSET",
  "GU_TILAVUOKRAUS",
  "GU_TILAVARAUS",
  "GU_RAVINTOLA",
  "GU_KOULULAIS",
  "GU_MUUT",
  "MANTTA_OPASTUKSET",
  "ERIKOISOHJELMAT",
  "HENKILOPALVELUT",
  "MUUT_KOHTEET"
];

export default productTypes;
