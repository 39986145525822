import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from 'NotFound';
import Home from './Home';
import { home } from './path';
import admin from './Admin/path';
import Products from './Products';
import products from './Products/path';
import Resources from './Resources';
import resources from './Resources/path';
import Resource from './Resources/Resource';
import resource from './Resources/Resource/path';
import Reservations from './Reservations';
import reservations from './Reservations/path';
import NewProduct from './Products/Product/NewProduct';
import newProduct from './Products/Product/path';
import Newsletters from './Newsletters';
import newsletter from './Newsletters/path';
import Reservation from './Reservations/Reservation';
import reservation from './Reservations/Reservation/path';
import { product } from './Products/Product/path';
import Product from "./Products/Product";
import Settings from "./Settings"
import settings from "./Settings/path"
import React from "react";

const Admin = lazy(() => import('./Admin'));

export default () => (
  <Switch>
    <Route exact path={home} component={Home} />
    <Route path={admin} component={Admin} />
    <Route path={products} component={Products} />
    <Route path={product} component={Product} />
    <Route path={resources} component={Resources} />
    <Route path={resource} component={Resource} />
    <Route path={reservations} component={Reservations} />
    <Route path={newProduct} component={NewProduct} />
    <Route path={newsletter} component={Newsletters} />
    <Route path={reservation} component={Reservation} />
    <Route path={settings} component={Settings} />
    <Route component={NotFound} />
  </Switch>
);
