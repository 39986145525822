import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import messages from 'intl/messages.properties';
import Button from '@material-ui/core/Button'

function BackButton(props) {
  return (
    <Button variant="contained"
            onClick={props.history.goBack}
            className={props.class}
    >
      <FormattedMessage id={messages.back} />
    </Button>
  );
}

export default BackButton |> withRouter;
