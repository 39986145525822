const programEditorStyle = theme => ({
  programEditorContent: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  programContentTop: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  },
  activeDatePaper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: '5px'
  },
  datePaper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: '5px'
  },
  programContent: {
    width: "100%",
    padding: "0 10px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
      padding: 20,
    },
  },
  split40: {
    width: "40%",
    padding: "0 10px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
      padding: 20,
    },
  },
  split60: {
    width: "60%",
    padding: "0 10px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
      padding: 20,
    },
  },
  greenButton: {
    margin: "15px 0 5px",
    float: "left",
    backgroundColor: "#068a3f",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#067a3d",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "0.85em"
    }
  },
  button: {
    margin: " 5px 0 5px 10px",
    [theme.breakpoints.down('md')]: {
      margin: "15px 0 5px",
      fontSize: "0.85em"
    },
    [theme.breakpoints.down('sm')]: {
      margin: "0 0 0 10px",
      fontSize: "0.85em"
    },
  }
});

export default programEditorStyle;
