import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import messages from 'intl/messages.properties';
import home from '../path';
import products from '../Products/path';
import resources from '../Resources/path';
import reservations from '../Reservations/path';

import {
  List,
  ListItem,
  SvgIcon
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUsers } from "@fortawesome/free-solid-svg-icons/index";
import pipedriveLogo from "../../images/pipedrive-logo.png";

export default () => (
  <List>
      <ListItem>
        <Link to={home}>
          <FontAwesomeIcon icon={faHome}
                           color={"#0B4A96"}
                           style={{ width: 24, height: "100%" }} />
          <FormattedMessage id={messages.homepage} />
        </Link>
      </ListItem>
      <ListItem>
        <Link to={reservations}>
          <SvgIcon viewBox="0 0 55 59">
            <path d="M8.58382 4.74359V1C8.58382 0.447715 9.03153 0 9.58381 0H13.3064C13.8586 0 14.3064 0.447715 14.3064 1V4.74359C14.3064 5.29587 14.7541 5.74359 15.3064 5.74359H36.1965C36.7488 5.74359 37.1965 5.29588 37.1965 4.74359V1C37.1965 0.447715 37.6442 0 38.1965 0H41.9191C42.4714 0 42.9191 0.447715 42.9191 1V4.74359C42.9191 5.29587 43.3708 5.74187 43.923 5.75412C50.2081 5.8935 51.5029 7.45892 51.5029 12.9231V21.9744C51.5029 22.5266 51.0552 22.9744 50.5029 22.9744H43.8763C43.3477 22.9744 42.9191 22.5458 42.9191 22.0171C42.9191 20.9597 42.0619 20.1026 41.0045 20.1026H10.5838C9.47925 20.1026 8.58382 20.998 8.58382 22.1026V48.2564C8.58382 49.361 9.47924 50.2564 10.5838 50.2564H19.0289C19.5812 50.2564 20.0289 50.7041 20.0289 51.2564V55C20.0289 55.5523 19.5812 56 19.0289 56H5.72254C1.43064 56 0 54.5641 0 50.2564V12.9231C0 7.45892 1.29482 5.8935 7.57992 5.75412C8.13207 5.74187 8.58382 5.29588 8.58382 4.74359Z"
                  fill="#0B4A96"/>
            <path d="M33.9428 56.1192L29.9424 56.1623C29.4198 56.168 28.9809 55.7701 28.9354 55.2494L28.572 51.0875C28.5473 50.8045 28.6441 50.5244 28.8383 50.317L43.2177 34.9603C43.5951 34.5572 44.228 34.5364 44.6311 34.9139L48.8346 38.8498C49.2345 39.2243 49.2587 39.8507 48.8889 40.2549L34.6697 55.7943C34.4828 55.9986 34.2196 56.1162 33.9428 56.1192Z"
                  fill="#0B4A96"/>
            <path d="M47.9543 29.9018L45.9351 32.0581C45.5577 32.4613 45.579 33.0935 45.9821 33.471L50.1773 37.3992C50.5804 37.7767 51.2127 37.7564 51.5902 37.3533L53.6093 35.197C54.116 34.6559 53.8882 33.7657 53.5112 33.4126L49.7412 29.8825C49.1756 29.353 48.4609 29.3607 47.9543 29.9018Z"
                  fill="#0B4A96"/>
          </SvgIcon>
          <FormattedMessage id={messages.reservations} />
        </Link>
      </ListItem>
      <ListItem>
        <Link to={products}>
          <SvgIcon viewBox="0 0 43 53">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.64913 12.9258C9.64938 11.366 10.9262 10.1018 12.5011 10.1018H30.4436C31.2002 10.1018 31.9255 10.3993 32.4604 10.929C32.9952 11.4588 33.2955 12.1772 33.2955 12.9264V40.1982C33.2955 40.9476 32.9952 41.666 32.4604 42.1958C31.9255 42.7255 31.2002 43.023 30.4436 43.023H12.4949C11.7386 43.023 11.013 42.7253 10.4782 42.1955C9.94331 41.6657 9.64298 40.9471 9.64324 40.1977C9.64452 32.6905 9.64785 20.4323 9.64913 12.9258ZM0.0822085 3.54842C0.306749 1.76159 2.41236 0.193356 4.2028 0.00924662C5.99324 -0.17461 8.4701 2.44426 10.8246 2.44426C13.7421 2.44426 18.0954 0.00747146 21.709 0.00924662C25.3229 0.0110218 29.9428 2.39177 32.5067 2.45492C34.1586 2.49574 36.0904 0.767241 37.7357 0.515675C41.115 -0.000643615 42.9513 2.80057 42.9987 4.45578C43.0611 6.65039 40.8987 10.2005 40.5625 13.2083C40.349 15.1179 42.1781 17.4365 42.229 19.8454C42.2764 22.072 40.5387 24.3757 40.5344 26.5046C40.5303 28.5858 42.0951 30.7908 42.036 32.9758C41.9684 35.4669 40.2875 37.9199 40.508 39.9304C40.8544 43.094 42.727 46.463 42.9096 48.6117C43.1423 51.3495 39.3364 53.5462 37.091 52.4174C35.6167 51.6764 34.2165 50.3473 32.5648 50.41C30.0012 50.5071 25.3598 53.0169 21.709 52.9999C18.0585 52.9829 13.4694 50.3078 10.6607 50.3078C8.52489 50.3078 6.44334 52.689 4.66187 52.6231C2.09795 52.528 0.389448 51.0315 0.0481561 48.9817C-0.319251 46.7744 2.65585 42.4344 2.65303 39.7554C2.65022 37.3143 0.0335624 35.1164 0.03049 32.9081C0.0276736 30.6995 2.63972 28.742 2.6346 26.5046C2.62948 24.2671 0.00898314 21.7466 2.19963e-05 19.4833C-0.00868312 17.22 2.56931 15.2706 2.58134 12.9241C2.59491 10.2682 -0.187906 5.70093 0.0822085 3.54842Z"
                  fill="#0B4A96"/>
          </SvgIcon>

          <FormattedMessage id={messages.itemsTitle} />
        </Link>
      </ListItem>
      <ListItem>
        <Link to={resources}>
          <FontAwesomeIcon icon={faUsers}
                           color={"#0B4A96"}
                           style={{ width: 24, height: "100%" }} />
          <FormattedMessage id={messages.resource} />
        </Link>
      </ListItem>
    <ListItem>
      <a target="_blank" rel="noopener noreferrer" href="https://toursandactivities.pipedrive.com">
        <img src={pipedriveLogo} alt="pipedriveLogo" style={{ height: "20px" }}/>
        <span>Pipedrive</span>
      </a>
    </ListItem>
  </List>
);
