import Page from 'Page';
import RequestErrorBoundary from "../../../shared/RequestErrorBoundary";
import { matchReservation, newReservation } from "./path";
import reservations from "../../Reservations/path";
import NewReservation from "./NewReservation";
import EditReservation from "./EditReservation";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

function Product() {
  return (
      <Page>
        <RequestErrorBoundary>
          <Switch>
            <Route path={newReservation} component={NewReservation} />
            <Route path={matchReservation} exact component={EditReservation} />
            <Redirect to={reservations} />
          </Switch>
        </RequestErrorBoundary>
      </Page>
  );
}

export default Product;
