const iconDialogStyle = ({
  button: {
    minWidth: 15,
    fontSize: 20
  },
  whiteIcon: {
    background: "#343434",
    padding: 5,
    margin: "10px 20px",
    '& path': {
      fill: "#FFF !important"
    }
  },
  dialogContent: {
    margin: "10px 20px",
  },
  dialogActions: {
    textAlign: "right",
  },
});

export default iconDialogStyle;
