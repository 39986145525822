import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import {CheckCircle, Close, Error} from "@material-ui/icons";
import SnackbarContent from "@material-ui/core/SnackbarContent";

function CustomSnackbar(props) {

    const {
        open = false,
        autoHideDuration = 10000,
        onClose,
        message,
        type = "success"
    } = props;


    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
        >
            <SnackbarContent
                style={{backgroundColor: type === "success" ? "green" : "red"}}
                aria-describedby="success-snackbar"
                message={
                    <span>
                        {type === "success" ? <CheckCircle/> : <Error/>}{' '}{message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                        <Close/>
                    </IconButton>
                ]}
            />
        </Snackbar>
    )

}

export default CustomSnackbar;
