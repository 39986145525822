import * as PropTypes from "prop-types";

import Form from "../Pages/Products/Product/Form";
import listStyle from '../styles/ListStyle';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

const useStyles = makeStyles(listStyle);

function Search( props ) {
  const classes = useStyles();
  const { searchValue, setSearchValue } = props;

  const handleSearchChange = prop => event => {
    setSearchValue(event.target.value);
  };

  return (
    <TextField
      id="searchValue"
      type="search"
      placeholder={'Haku'}
      value={searchValue}
      className={classes.searchField}
      variant="filled"
      onChange={ handleSearchChange() }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start"
                          style={{margin: 0}}>
            <FontAwesomeIcon icon={faSearch} />
          </InputAdornment>
        )
      }}
    />
  )
}

Form.propTypes = {
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
};

export default Search;
