import { forwardRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from "@fortawesome/free-solid-svg-icons/index";

const tableIcons = {
  Add: forwardRef(() => <FontAwesomeIcon icon={icons.faPlus} />),
  Check: forwardRef(() => <FontAwesomeIcon icon={icons.faCheck} />),
  Clear: forwardRef(() => <FontAwesomeIcon icon={icons.faTimes} />),
  Delete: forwardRef(() => <FontAwesomeIcon icon={icons.faTrash} />),
  DetailPanel: forwardRef(() => <FontAwesomeIcon icon={icons.faChevronDown} />),
  Edit: forwardRef(() => <FontAwesomeIcon icon={icons.faEdit} />),
  Export: forwardRef(() => <FontAwesomeIcon icon={icons.faFileExport} />),
  Filter: forwardRef(() => <FontAwesomeIcon icon={icons.faFilter} />),
  FirstPage: forwardRef(() => <FontAwesomeIcon icon={icons.faFastBackward} />),
  LastPage: forwardRef(() => <FontAwesomeIcon icon={icons.faFastForward} />),
  NextPage: forwardRef(() => <FontAwesomeIcon icon={icons.faChevronRight} />),
  PreviousPage: forwardRef(()  => <FontAwesomeIcon icon={icons.faChevronLeft} />),
  ResetSearch: forwardRef(() => <FontAwesomeIcon icon={icons.faTimes} />),
  Search: forwardRef(() => <FontAwesomeIcon icon={icons.faSearch} />),
  SortArrow: forwardRef(() => <FontAwesomeIcon icon={icons.faChevronUp} />),
  ThirdStateCheck: forwardRef(() => <FontAwesomeIcon icon={icons.faTrash} />),
  ViewColumn: forwardRef(() => <FontAwesomeIcon icon={icons.faChevronDown} />)
};

export default tableIcons;
