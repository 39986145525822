import { Suspense } from 'react';
import Spinner from 'Spinner';
import Title from './Title';
import AppBarMenu from './AppBarMenu';
import PageContainer from './Page';
import Footer from './Footer';

export default function Page({ children }) {
  return (
    <>
      <AppBarMenu />
      <Suspense fallback={<Spinner />}>
        <PageContainer>{children}</PageContainer>
      </Suspense>
      <Footer/>
    </>
  );
}

Page.Title = Title;

export Title from './Title';
