import Page from 'Page';
import React from "react";
import EditResource from "./EditResource";
import NewResource from "./NewResource";
import { matchResource, newResource } from "./path";
import resources from "../path"
import { Route, Switch, Redirect } from "react-router-dom";
import RequestErrorBoundary from "../../../shared/RequestErrorBoundary";

function Resource() {
  return (
      <Page>
        <RequestErrorBoundary>
          <Switch>
            <Route path={newResource} component={NewResource} />
            <Route path={matchResource} exact component={EditResource} />
            <Redirect to={resources} />
          </Switch>
        </RequestErrorBoundary>
      </Page>
  );
}

export default Resource;
