const qualificationsDialogStyle = theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: "#ffffff",
    boxShadow: "none",
    color: "#242424",
    textTransform: "none",
  },
  appBarTab: {
    minWidth: 80,
    borderRight: "solid 1px #e4e4e4"
  },
  button: {
    minWidth: 15,
    fontSize: 20
  },
  actionButton: {
    [theme.breakpoints.down('md')]: {
      fontSize: "0.9em"
    }
  },
  label: {
    width: "100%",
    display: "inline",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  dialogActions: {
    margin: 10,
    justifyContent: "space-between"
  },
  dialogList: {
    width: "100%",
    background: "#f2f2f2",
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
      margin: 0,
      padding: 20,
    },
  },
  split: {
    width: "50%",
    padding: "0 20px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
      padding: 20,
    },
  },
  split40: {
    width: "40%",
    padding: "0 10px",
    '& .MuiPaper-elevation2' : {
      boxShadow: "none",
      background: "#f4f4f4",
      "& searchField": {
        background: "#fff",
        padding: "5px 10px",
        borderRadius: 10,
        '& svg.fa-times' : {
          height: "0.7em",
        },
      },
      '& .MuiTableRow-head > *' : {
        background: "#f4f4f4",
      },
    },
    '& .MuiToolbar-gutters' : {
      paddingLeft: "15px !important",
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
      padding: 20,
    },
  },
  split60: {
    width: "60%",
    boxShadow:  "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    borderCollapse: "separate",
    padding: 10,
    margin: "0 5px",
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
      padding: 20,
    },
  },
  checkboxClass: {
    padding: 4,
  },
  greenButton: {
    margin: "0 0 0 10px",
    float: "right",
    backgroundColor: "#068a3f",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#067a3d",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "0.8em"
    }
  },
  languageList: {
    width: "100%",
    flexDirection: "column",
  },
  languagesContainer: {
    flexDirection: "row",
    '& span': {
      margin: "0 2px",
    }
  },
  language: {
    marginLeft: 0,
  },
  qualificationLanguage: {
    float: "right",
  },
  tableHead: {
    padding: "10px 0",
    '& th': {
      padding: "10px 0 !important",
    }
  },
  tableBody: {
    padding: "10px 0",
    '& td': {
      padding: "10px 0 !important",
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  removeButton: {
    padding: 5,
    minWidth: 10,
  }
});

export default qualificationsDialogStyle;

