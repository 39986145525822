import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';

import BodyTextEditor from "../../../../../shared/RTE";

import {
  Paper,
  Button,
  FormControl
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles/index";
import formStyle from "../../../../../styles/FormStyle";

const useStyles = makeStyles(formStyle);

export default function OfferBase(props) {
  const { offerValue, handleSave, setShowEditor } = props;
  const classes = useStyles();

  const [ content, setContent ] = React.useState( {...offerValue}.offerContent );

  return ( <>
      <Paper className={classes.paper}>
        <h3 className={classes.header3}>
          { offerValue.name }
        </h3>
        <label className={classes.label}>
          <FormattedMessage id={messages.content} />
        </label>
        <BodyTextEditor value={content}
                        setValue={setContent}
                        classname="offerEditor" />
      </Paper>
      <FormControl className={classes.formControlButtons}>
        <Button className={classes.button}
                variant="contained"
                onClick={ () => setShowEditor(false) }>
          <FormattedMessage id={messages.back} />
        </Button>
        <Button className={classes.greenButton}
                variant="contained"
                onClick={ () => handleSave(offerValue, content) }>
          <FormattedMessage id={messages.save} />
        </Button>
      </FormControl>
    </>
  )
}
