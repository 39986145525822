import { Container } from 'react-bootstrap';

export default function Page({ className, style, children }) {

  return (
    <Container className={className + " p-3"} fluid style={{ ...style, minHeight: "calc(100vh - 124px)" }}>
      {children}
    </Container>
  );
}
