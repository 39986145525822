import React from 'react';
import Button from "@material-ui/core/Button";
import moment from "moment";
import messages from 'intl/messages.properties';
import Authorize from "@postinumero/authorization/Authorize";
import Dotdotdot from "react-dotdotdot";
import {FormattedMessage} from "react-intl";
import { Link } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import removeMd from "remove-markdown";
import {makeStyles} from "@material-ui/core";
import listStyle from "../../../styles/ListStyle";

// this button takes to a newsletter creation page, admin only
function AddNewsButton() {

  return (
    <Authorize allow="Asiakaspalvelu">
      <Link to={`/newsletter/new`}>
        <Button variant="contained"
                id="add-news-button"
                className="news-button"
          >
          <FontAwesomeIcon icon={faPlus}
                           style={{marginRight: 10}}/>
          <FormattedMessage id={messages.newsletterAddPage} />
        </Button>
      </Link>
    </Authorize>
  );
}

// returns newsletters' titles, writers, snippet of the body text, publishing date and link to the full text
function NewsletterInfo({ info }) {

  const body = removeMd(info.bodyText);

  return (
    <div className="newsletter-front-div">
      <h5>
        {info.title}
      </h5>
      <h6 className="newsletter-writer">
        {info.writerName}
      </h6>
      <div className="front-body-height-backup">
        <Dotdotdot clamp={3}>
            {body}
        </Dotdotdot>
      </div>
      <p className="date-style">
        {moment(info.createdTimestamp).format("DD.MM.YYYY")}
      </p>
      <ReadMoreLink
        infoId={info.id}
      />
      <hr/>
    </div>
  );
}

// this button takes one to read the full newsletters
function ReadMoreLink({ infoId }) {
  return (
    <Link className="read-more-link news-button"
          to={`/newsletter/${infoId}`}>
        <FormattedMessage id={messages.newsletterReadMore} />
    </Link>
  );
}

const useStyles = makeStyles(listStyle);

// Newsletter component to the front page, so users can see important topics regarding the workplace
export default function Newsletter ({ newsletterData }) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const firstNewsOnThePage = page * rowsPerPage;

  // checks, if the expiration date for a newsletter has gone or if it will show the newsletter
  const shownNews = newsletterData
    .filter(info => moment(info.expirationDate).diff(moment().subtract(1, 'days')) >= 0 || info.expirationDate === null)
    .reverse();

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <div>
      <AddNewsButton/>
      {
        shownNews.length
          ? <>
              <List>
                {
                  shownNews
                    .slice(firstNewsOnThePage, firstNewsOnThePage + rowsPerPage)
                    .map((info, index) =>
                      <ListItem key={index}>
                        <NewsletterInfo info={info} />
                      </ListItem>
                    )
                }
              </List>
              <TablePagination
                className={classes.pagination}
                component="div"
                count={shownNews.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[3, 5, 10]}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                labelRowsPerPage={<FormattedMessage id={messages.rowsPerPage}/>}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          : <FormattedMessage id={messages.newsletterNone} />
      }
    </div>
  );
}
