import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';
import IconDialogStyle from '../../../styles/IconDialogStyle';
import iconsArray from '../../../shared/definitions/iconsArray';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(IconDialogStyle);

export default function IconDialog(props) {
  const classes = useStyles();
  const { onClose, open, selectedMuseum } = props;

  function handleListItemClick(value) {
    onClose(`${selectedMuseum}|${value}`);
  }

  return (
    <Dialog aria-labelledby="simple-dialog-title"
            open={open}
            maxWidth = 'lg'
            fullWidth={true}>
      <DialogTitle id="simple-dialog-title"
                   children={''}>
        <FormattedMessage id={messages.dialogTitle}/>
      </DialogTitle>
      <div className={ selectedMuseum === 'gustaf'
        ? classes.whiteIcon
        : classes.dialogContent
      }>
        {iconsArray.map(([name, icon]) =>
            <Button key={name}
                    className={classes.button}
                    onClick={() => handleListItemClick(name)}>
              <FontAwesomeIcon icon={icon}/>
            </Button>
        )}
      </div>

      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => onClose()}
                color="default"
                variant="contained">
          <FormattedMessage id={messages.cancel}/>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

IconDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  selectedMuseum: PropTypes.string.isRequired,
};
