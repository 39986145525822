let languageList = [
  "FI",
  "SV",
  "EN",
  "DE",
  "FR",
  "RU",
  "IT",
  "ES",
  "FI_SIMPLIFIED",
  "OTHER",
];

export default languageList;
