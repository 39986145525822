const listStyle = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  pagination: {
    margin: "20px 0 0",
    display: "flex",
    flex: 0,
    justifyContent: "center",
    padding: "0 !important"
  },
  gustafIcon: {
    width: "30px !important",
    height: "30px !important",
    padding: 6,
    borderRadius: "100%",
    fontSize: 30,
    background: "#6f6f6f",
    '& path': {
      fill: "#FFF !important"
    }
  },
  gostaIcon: {
    width: "30px !important",
    height: "30px !important",
    padding: 2,
    borderRadius: "100%",
    fontSize: 30,
  },
  searchContainer: {
    margin: "10px 20px 10px",
    display: "flex",
    justifyContent: "flex-end",
  }
});

export default listStyle;


