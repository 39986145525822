import Page from 'Page';
import Private from './Private';

export default function Home() {
  return (
    <Page>
      <Private />
    </Page>
  );
}
