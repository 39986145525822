export const customTheme = {
  palette: {
    primary: {
      light: '#686867',
        main: '#3e3e3d',
        dark: '#181817',
        contrastText: '#fff'
    },
    secondary: {
      light: '#d5c28f',
        main: '#a39261',
        dark: '#736536',
        contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: "#eeeeee",
        fontWeight: 500,
      }
    },
  },
};
