import React from 'react';
import {FormattedMessage} from "react-intl";
import messages from 'intl/messages.properties';
import * as PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/index";

export default function RemoveFileDialog(props) {
  const { data, removeFile, classes, open, setOpen} = props;

  function handleClose() {
    setOpen(false);
  }

  function handleAddFile() {
    removeFile(data);
    setOpen(false);
  }

  const handleRemoveMessage = () => {
    if(!data) {
      return data
    } else if (Array.isArray(data)){
      let msg = "";
      for (let i = 0; i < data.length; i++) {
        msg += data[i].name;
        if (i < data.length - 1) {
          msg += ", "
        }
      }
      return msg
    } else {
      return data.name
    }
  };

  return (
    <Dialog open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id={messages.confirmModalTitleFileRemove}/>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id={messages.confirmModalBodyFileRemove}
                            values={{name: handleRemoveMessage()}}/>
        </DialogContentText>
      </DialogContent>

      <Divider/>

      <DialogActions>
        <Button onClick={handleClose}
                color="primary"
                style={{ margin: "5px 10px 5px 5px" }}>
          <FormattedMessage id={messages.cancel}/>
        </Button>
        <Button onClick={() => handleAddFile()}
                className={classes.greenButton}
                style={{ margin: "5px 0 5px 5px" }}>
          <FontAwesomeIcon icon={ faTrashAlt }
                           style={{marginRight: 10}} />
          <FormattedMessage id={messages.add}/>
        </Button>
      </DialogActions>
    </Dialog>
  );
}


RemoveFileDialog.propTypes = {
  data: PropTypes.array,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};
